import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class QuestionReportService {
  private reportData: any = {};

  constructor() {}

  saveReportData(data: any) {
    this.reportData = data;
  }

  getReportData() {
    return this.reportData;
  }

  clearReportData() {
    this.reportData = {}; // Clears all stored data
  }
}
