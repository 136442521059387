import { Component, Input, OnInit } from '@angular/core';
import { HeadingQuestion } from '../../../core/models/inspection/inspection.model';
import { InspectionService } from '../../../core/services/inspection/inspection.service';

@Component({
  selector: 'app-standpipe-hose',
  templateUrl: './standpipe-hose.component.html',
  styleUrls: ['./standpipe-hose.component.scss']
})
export class StandpipeHoseComponent implements OnInit {

  hose: any;
  @Input() questions: Array<HeadingQuestion>;
  @Input() qtd: Array<any>;
  @Input() reviewInspection: boolean;
  constructor(private insService: InspectionService) { }

  ngOnInit() {
    if (this.insService.getHoseCount() > 0) {
      this.hose = [{ id: 0 }];
      for (let i = 1; i < this.insService.getHoseCount(); i++) {
        this.hose.push({ id: i });
      }
    }
    if (this.insService.getHoseRows() && this.insService.getHoseRows().length > 0) {
      this.hose = this.insService.getHoseRows();
      if(this.qtd[this.findQuestionIdSecond('Hose Location(floor/Stair)') + '~hose~' + 0 + '~']==null){
      for (let m = 0; m < this.hose.length; m++) {
        this.qtd[this.findQuestionIdSecond('Hose Location(floor/Stair)') + '~hose~' + m + '~'] =
          JSON.parse(JSON.stringify(this.hose[m]['Hose Location(floor/Stair)'])) || '';
        this.qtd[this.findQuestionIdSecond('Size(inches)') + '~hose~' + m + '~'] =
          JSON.parse(JSON.stringify(this.hose[m]['Size(inches)'])) || '';
        this.qtd[this.findQuestionIdSecond('Length(ft)') + '~hose~' + m + '~'] =
          JSON.parse(JSON.stringify(this.hose[m]['Length(ft)'])) || '';
        this.qtd[this.findQuestionIdSecond('Evaluation') + '~hose~' + m + '~'] =
          JSON.parse(JSON.stringify(this.hose[m].Evaluation)) || '';
        this.qtd[this.findQuestionIdSecond('Test Psi') + '~hose~' + m + '~'] =
          JSON.parse(JSON.stringify(this.hose[m]['Test Psi'])) || '';
        this.qtd[this.findQuestionIdSecond('Comments') + '~hose~' + m + '~'] =
          JSON.parse(JSON.stringify(this.hose[m].Comments)) || '';
      }
    }
    } else {
      if (this.insService.getHoseRows() <= 0 && !this.hose) {
        this.hose = [{ id: 0 }];
      }
    }
  }

  addRow() {
    this.hose.push({ id: this.hose.length });
    this.insService.setHoseCount(this.hose.length);
  }

  deleteRow(index) {
    this.hose = this.hose.filter((x) => x.id !== index.id);
    for (const key of Object.keys(this.qtd)) {
      if (key.split('~') && key.indexOf('hose') > 0) {
        delete this.qtd[key];
      }
    }
    this.rebuildDOM();
    this.insService.setHoseCount(this.hose.length);
  }

  findQuestionIdSecond(description?) {
    if (this.questions) {
      if (this.questions.filter(x => x.questions.question === description) &&
        this.questions.filter(x => x.questions.question === description)[0]) {
        return this.questions.filter(x => x.questions.question === description)[0].questions.questionId;
      }
    }
    return 0;

  }

  updateModel(question, id, k) {
    this.qtd[this.findQuestionIdSecond(question) + '~hose~' + id + '~'] = JSON.parse(JSON.stringify(k[question]));

    console.log(this.qtd);
    
  }

  rebuildDOM() {
    for (let m = 0; m < this.hose.length; m++) {
      this.qtd[this.findQuestionIdSecond('Hose Location(floor/Stair)') + '~hose~' + m + '~'] =
        this.hose[m]['Hose Location(floor/Stair)'] ? JSON.parse(JSON.stringify(this.hose[m]['Hose Location(floor/Stair)'])) : '';
      this.qtd[this.findQuestionIdSecond('Size(inches)') + '~hose~' + m + '~'] =
        this.hose[m]['Size(inches)'] ? JSON.parse(JSON.stringify(this.hose[m]['Size(inches)'])) : '';
      this.qtd[this.findQuestionIdSecond('Length(ft)') + '~hose~' + m + '~'] =
        this.hose[m]['Length(ft)'] ? JSON.parse(JSON.stringify(this.hose[m]['Length(ft)'])) : '';
      this.qtd[this.findQuestionIdSecond('Evaluation') + '~hose~' + m + '~'] =
        this.hose[m].Evaluation ? JSON.parse(JSON.stringify(this.hose[m].Evaluation)) : '';
      this.qtd[this.findQuestionIdSecond('Test Psi') + '~hose~' + m + '~'] =
        this.hose[m]['Test Psi'] ? JSON.parse(JSON.stringify(this.hose[m]['Test Psi'])) : '';
      this.qtd[this.findQuestionIdSecond('Comments') + '~hose~' + m + '~'] =
        this.hose[m].Comments ? JSON.parse(JSON.stringify(this.hose[m].Comments)) : '';
    }

  }

}
