import { Component, OnInit, Input } from '@angular/core';
import { HeadingQuestion } from '../../../../core/models/inspection/inspection.model';

@Component({
  selector: 'app-communication-devices',
  templateUrl: './communication-devices.component.html',
  styleUrls: []
})
export class CommunicationDevicesComponent implements OnInit {
  @Input() questions: Array<HeadingQuestion>;
  @Input() qtd: Array<any>;
  @Input() reviewInspection: boolean;
  communicationTable;
  constructor() { }

  ngOnInit() {
    this.init();
  }

  init() {
    this.communicationTable = {
      phoneSets: {
        'unitsInBuilding': this.findAnswerbyQuestion('Phone Sets', 'unitsInBuilding') || '',
        'unitsTested': this.findAnswerbyQuestion('Phone Sets', 'unitsTested') || '',
        'resultsAcceptable': this.findAnswerbyQuestion('Phone Sets', 'resultsAcceptable') || 'N/A'
      },
      phoneJacks: {
        'unitsInBuilding': this.findAnswerbyQuestion('Phone Jacks', 'unitsInBuilding') || '',
        'unitsTested': this.findAnswerbyQuestion('Phone Jacks', 'unitsTested') || '',
        'resultsAcceptable': this.findAnswerbyQuestion('Phone Jacks', 'resultsAcceptable') || 'N/A'
      },
      callInSignal: {
        'unitsInBuilding': this.findAnswerbyQuestion('Call-in Signal', 'unitsInBuilding') || '',
        'unitsTested': this.findAnswerbyQuestion('Call-in Signal', 'unitsTested') || '',
        'resultsAcceptable': this.findAnswerbyQuestion('Call-in Signal', 'resultsAcceptable') || 'N/A'
      }
    }
  }

  ngOnChanges(changes) {
    if (changes['questions'] && changes['questions'].currentValue) {
      this.questions = [...changes['questions'].currentValue];
      this.init();
    }
  }

  findAnswerbyQuestion(question, description) {
    let myQuestion = this.questions.filter(x => x.questions.question === question)
    if (myQuestion.length > 0) {
      if (myQuestion[0].questions &&
        myQuestion[0].questions.tableAnswers &&
        myQuestion[0].questions.tableAnswers.length > 0 &&
        myQuestion[0].questions.tableAnswers[0][description] && this.qtd[this.findQuestionId(question)][description]==null) {
        this.updateModel(question, description, myQuestion[0].questions.tableAnswers[0][description]);
        return myQuestion[0].questions.tableAnswers[0][description]
      } else {
        if (description === 'resultsAcceptable') {
          this.updateModel(question, description, 'N/A');
          return 'N/A'
        } else {
          return '';
        }
      }
    }
    return;
  }

  findQuestionId(description: string) {
    let myQuestion = this.questions.filter(x => x.questions.question === description)
    if (myQuestion.length > 0) {
      return myQuestion[0].questions.questionId;
    }
    return 0;
  }

  updateModel(question, column, value) {
    const id = this.findQuestionId(question);
    if (!this.qtd[id] || typeof this.qtd[id][0] === 'string') {
      this.qtd[id] = {};
    }
    if (column === 'unitsInBuilding') {
      this.qtd[id][column] = value;
    } else if (column === 'unitsTested') {
      this.qtd[id][column] = value;
    } else if (column === 'resultsAcceptable') {
      if(!this.qtd[id][column]){
      this.qtd[id][column] = value;
      }
    }
  }
}
