import * as tslib_1 from "tslib";
import { DatePipe } from "@angular/common";
import { EventEmitter, OnDestroy, OnInit, AfterViewChecked, ElementRef, OnChanges, } from "@angular/core";
import * as moment from "moment";
import { MatRadioChange, } from "@angular/material/radio";
var ɵ0 = { color: "accent" };
var QuestionsComponent = /** @class */ (function () {
    function QuestionsComponent(inspectionService, route, router, datepipe, authorityService, loggerService, location, el, questionReportService) {
        this.inspectionService = inspectionService;
        this.route = route;
        this.router = router;
        this.datepipe = datepipe;
        this.authorityService = authorityService;
        this.loggerService = loggerService;
        this.location = location;
        this.el = el;
        this.questionReportService = questionReportService;
        this.backButton = new EventEmitter();
        this.emitErrorMessages = new EventEmitter();
        this.downstreamTypes = ["DCVA", "RPBA", "PVBA", "OTHER"];
        this.downstreamOthersValue = false;
        this.isViewMode = false;
        this.downStreamOthers = "";
    }
    QuestionsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.assetTypeid = this.inspectionService.getAssetTypeId();
        this.submitted = false;
        this.uploadError = [];
        this.userRole = localStorage.getItem("userRole");
        this.replacementValue = this.qtd[this.findQuestionId("Install:")]
            ? this.qtd[this.findQuestionId("Install:")] === "Replacement"
                ? true
                : false
            : false;
        this.assetTypeName = this.questions
            ? this.questions[0]
                ? this.questions[0]["assetType"]
                    ? this.questions[0]["assetType"]
                    : ""
                : ""
            : "";
        // if (this.userRole === 'ROLE_AUTHORITY') {
        //   this.authorityService.getAuthorityPreferences().subscribe((response) => {
        //     if (response && response['authoritySettingDetails']) {
        //       this.isPictureRequired = response['authoritySettingDetails']['isPictureRequired']
        //     }
        //   });
        // } else {
        //   this.isPictureRequired = true;
        // }
        this.loading = true;
        if (window.location.href.indexOf("addEditProperty") > 0) {
            this.addEditProperty = true;
        }
        else {
            this.addEditProperty = false;
        }
        if (window.location.href.indexOf("configureInspectionReport") > 0) {
            this.configureReport = true;
            this.reviewInspection = true;
            this.assetTypeid = this.inspectionService.getAssetTypeId();
        }
        else {
            this.configureReport = false;
            this.reviewInspection = false;
        }
        this.class1Questions = [
            "Hydro-tested 150psi or 50 psi greater than pressure for 2 hours (Dry Standpipe Only)",
            'All outlet valves and hose threads checked and have 2 1/2" Cap with 1/8th inch hole?',
            "All outlet valves are fully operational and function properly:",
            "Was 25 psi air test conducted?",
            "Flow test conducted?",
        ];
        this.class2Questions = [
            "Hydro-tested 150 psi or 50 psi greater than head pressure for 2 hours:",
            "Are all hoses, valves and controlling nozzles in good condition?",
            "Have flow test been conducted at highest level for at least\n      30 seconds to make sure nozzle will work at pressure available (50 gpm at 35 psi minimum)?",
            "Have controlling valves been tested to verify that pressure regulating valves operate properly\n      (not to exceed 100 psi Tip pressure)?",
        ];
        this.class3Questions = [
            "Hydro-tested at 150 psi greater than highest Operating pressure?",
            "Was 25 psi air test conducted?",
            'All outlet valves and hose threads checked and have 2 1/2" Cap with 1/8th inch hole?',
            "Flow tests conducted to verify operating pressure of pressure regulating valves (not to exceed 175-psi flowing)?",
        ];
        this.sequence = [];
        this.initialValue = "";
        this.subscriptions = [];
        this.checkBoxQuestions = [];
        this.imageUploadQuestions = [];
        this.headingUploadQuestions = [];
        this.additionalQuestionExist = false;
        if (this.questions) {
            this.questions.forEach(function (x) {
                if (x &&
                    x.questions &&
                    x.questions.optionType === "Checkbox" &&
                    x.questions.questionType !== "DASC") {
                    _this.checkBoxQuestions.push(x.questions.questionId);
                }
                if (x && x.questions && x.questions.isImageUploadEnabled) {
                    if (x.questions.mainHeading === "Heading") {
                        _this.headingUploadQuestions.push(x.questions.questionId);
                    }
                    else {
                        _this.imageUploadQuestions.push(x.questions.questionId);
                    }
                }
                if (x &&
                    x.questions &&
                    x.questions.questionType &&
                    x.questions.questionType === "Additional") {
                    _this.additionalQuestionExist = true;
                }
                if (x.questions.subquestion) {
                    x.questions.subquestion.forEach(function (y) {
                        if (y.isImageUploadEnabled) {
                            if (y.mainHeading === "Heading") {
                                _this.headingUploadQuestions.push(y.questionId);
                            }
                            else {
                                _this.imageUploadQuestions.push(y.questionId);
                            }
                        }
                    });
                }
            });
        }
        if (window.location.href.indexOf("reviewInspection") > 0 ||
            window.location.href.indexOf("printReport") > 0 ||
            window.location.href.indexOf("configureInspectionReport") > 0) {
            this.reviewInspection = true;
            if (window.location.href.indexOf("configureInspectionReport") > 0) {
                this.assetTypeid = this.inspectionService.getAssetTypeId();
            }
            else {
                this.assetTypeid = window.location.href.substring(window.location.href.indexOf("id=") + 3, window.location.href.length);
            }
            if (this.inspectionDataforSecondPage) {
                this.sequence = this.inspectionDataforSecondPage.sequence;
                this.showHose = this.inspectionDataforSecondPage.showHose;
                this.showPRV = this.inspectionDataforSecondPage.showPRV;
            }
        }
        else {
            this.reviewInspection = null;
        }
        this.invalidAnswers = [];
        this.inspection = {};
        this.inspection.phoneNo = "425-771-1166";
        this.inspection.testingAgency = "ABSCO Alarms Inc.";
        this.inspection.mailingAddress = "	19023 36TH AVE W STE ELYNNWOOD, WA 98036";
        this.yesCases = [1039, 1573];
        this.subscriptions.push(this.route.queryParams.subscribe(function (params) {
            if (params.viewMode) {
                _this.isViewMode = params.viewMode === "false" ? false : true;
                _this.reviewInspection = _this.isViewMode;
            }
            if (_this.router.url.indexOf("fileCabinet") > -1) {
                _this.isViewMode = true;
                _this.reviewInspection = _this.isViewMode;
                if (_this.inspectionDataforSecondPage &&
                    _this.inspectionDataforSecondPage.inspectedDate) {
                    _this.inspectedDate = _this.datepipe.transform(new Date(_this.inspectionDataforSecondPage.inspectedDate), "MM-dd-yyyy");
                }
                if (_this.inspectionDataforSecondPage) {
                    _this.additionalRemarks =
                        _this.inspectionDataforSecondPage.inspectionRemarks;
                    _this.additional = _this.inspectionDataforSecondPage
                        .isInspectorCustomArea
                        ? "yes"
                        : "no";
                    _this.systemStatus = _this.inspectionDataforSecondPage.systemStatus;
                }
                _this.checkbox_authority = true;
            }
            if (_this.router.url.indexOf("dashboardAuthority") > -1) {
                _this.isViewMode = true;
                _this.reviewInspection = _this.isViewMode;
            }
            _this.assetId = params.assetId;
            _this.propertyId = params.propId;
            _this.inspectionReportID = params.editMode;
            _this.assetTypeid = params.id;
            if (_this.viewReportData && _this.viewReportData.id) {
                _this.assetTypeid = _this.viewReportData.id;
            }
            _this.pac = params.pac;
            _this.isImageUploadEnabled = params.isImageUploadEnabled === "true";
            _this.isSimplifiedReportEnabled =
                params.isSimplifiedReportEnabled === "true";
            if (_this.inspectionReportID && _this.inspectionDataforSecondPage) {
                _this.showHose = _this.inspectionDataforSecondPage.showHose;
                _this.showPRV = _this.inspectionDataforSecondPage.showPRV;
                _this.sequence = _this.inspectionDataforSecondPage.sequence;
                _this.authRemarks = _this.inspectionDataforSecondPage.authorityRemarks;
                if (_this.inspectionDataforSecondPage.inspectedDate) {
                    (_this.inspectedDate = new Date(_this.inspectionDataforSecondPage.inspectedDate)),
                        "MM-DD-YYYY";
                }
                _this.attachment =
                    _this.inspectionDataforSecondPage.inspectionAttachment;
                if (_this.inspectionDataforSecondPage.inspectionAttachment &&
                    _this.inspectionDataforSecondPage.inspectionAttachment[0] &&
                    _this.inspectionDataforSecondPage.inspectionAttachment[0]
                        .imagePlacement !== "Simple") {
                    _this.attachment =
                        _this.inspectionDataforSecondPage.inspectionAttachment;
                }
                _this.additionalRemarks =
                    _this.inspectionDataforSecondPage.inspectionRemarks;
                _this.additional = _this.inspectionDataforSecondPage
                    .isInspectorCustomArea
                    ? "yes"
                    : "no";
                _this.systemStatus = _this.inspectionDataforSecondPage.systemStatus;
            }
        }));
        if (!this.attachment || (this.attachment && this.attachment.length === 0)) {
            if (this.inspectionDataforSecondPage) {
                this.attachment = this.inspectionDataforSecondPage.inspectionAttachment;
            }
        }
        if (this.attachment && this.attachment.length > 0) {
            this.attachment.forEach(function (x) {
                if (x.imagePlacement === "Simple") {
                    _this.imagePlacement = true;
                    _this.imageSimplified = x.attachment;
                    _this.fileNameSimplified = x.imageName;
                }
                if (x.imagePlacement === "Main") {
                    _this.image = x.attachment;
                    _this.fileName = x.imageName;
                }
                if (x.imagePlacement !== "Main" && _this.assetTypeid === "14") {
                    if (x.imagePlacement === "Second") {
                        _this.fileName4 = x.imageName;
                        _this.image4 = x.attachment;
                    }
                    else if (x.imagePlacement === "Third") {
                        _this.fileName2 = x.imageName;
                        _this.image2 = x.attachment;
                    }
                    else if (x.imagePlacement === "Fourth") {
                        _this.fileName3 = x.imageName;
                        _this.image3 = x.attachment;
                    }
                }
                if (x.imagePlacement !== "Main" &&
                    x.imagePlacement !== "Simple" &&
                    _this.assetTypeid !== "14") {
                    if (!_this.photo1) {
                        _this.photo1 = x.attachment;
                        _this.photo1Name = x.imageName;
                        return;
                    }
                    if (!_this.photo2) {
                        _this.photo2 = x.attachment;
                        _this.photo2Name = x.imageName;
                        return;
                    }
                    if (!_this.photo3) {
                        _this.photo3 = x.attachment;
                        _this.photo3Name = x.imageName;
                        return;
                    }
                    if (!_this.photo4) {
                        _this.photo4 = x.attachment;
                        _this.photo4Name = x.imageName;
                        return;
                    }
                }
            });
        }
        if (this.inspectionDataforSecondPage) {
            this.showSimplified =
                this.inspectionDataforSecondPage.inspectionType === "SIMPLIFIED_REPORT"
                    ? true
                    : false;
            if (this.inspectionDataforSecondPage.inspectionAttachment) {
                if (this.inspectionDataforSecondPage.inspectionAttachment.find(function (x) { return x.imagePlacement !== "Simple"; })) {
                    this.attachmentOriginal =
                        this.inspectionDataforSecondPage.inspectionAttachment.find(function (x) { return x.imagePlacement !== "Simple"; });
                }
            }
        }
        this.loading = false;
        this.configureFireAndSmokeDamperAsset();
        var savedData = this.questionReportService.getReportData();
        if (Object.keys(savedData).length > 0 && this.assetId && this.assetId === savedData.assetId) {
            this.base64logoImage = savedData.image;
            this.inspectedDate = savedData.inspectedDate ? new Date(savedData.inspectedDate) : null;
            this.systemStatus = savedData.systemStatus;
            this.additional = savedData.additional;
            this.additionalRemarks = savedData.additionalRemarks;
            this.fileName = savedData.fileName;
            this.imageUploadError = savedData.imageUploadError;
            this.image = savedData.image;
            this.photo1 = savedData.photo1;
            this.photo1Name = savedData.photo1Name;
            this.photo1Error = savedData.photo1Error;
            this.photo2 = savedData.photo2;
            this.photo2Name = savedData.photo2Name;
            this.photo2Error = savedData.photo2Error;
            this.photo3 = savedData.photo3;
            this.photo3Name = savedData.photo3Name;
            this.photo3Error = savedData.photo3Error;
            this.photo4 = savedData.photo4;
            this.photo4Name = savedData.photo4Name;
            this.photo4Error = savedData.photo4Error;
            this.fileName2 = savedData.fileName2;
            this.image2 = savedData.image2;
            this.imageUploadError2 = savedData.imageUploadError2;
            this.fileName3 = savedData.fileName3;
            this.image3 = savedData.image3;
            this.imageUploadError3 = savedData.imageUploadError3;
            this.fileName4 = savedData.fileName4;
            this.image4 = savedData.image4;
            this.imageUploadError4 = savedData.imageUploadError4;
        }
    };
    QuestionsComponent.prototype.isValidCommertialDate = function (dateString) {
        // First check for the pattern
        if (!/^\d{1,2}-\d{4}$/.test(dateString)) {
            return false;
        }
        // Parse the date parts to integers
        var parts = dateString.split("-");
        var year = parseInt(parts[1], 10);
        var month = parseInt(parts[0], 10);
        // Check the ranges of month and year
        if (year < 1000 || year > 3000 || month === 0 || month > 12) {
            return false;
        }
        else {
            return true;
        }
    };
    // Validates that the input string is a valid date formatted as "mm/dd/yyyy"
    QuestionsComponent.prototype.isValidDate = function (dateString) {
        // First check for the pattern
        if (!/^\d{1,2}-\d{1,2}-\d{4}$/.test(dateString)) {
            return false;
        }
        // Parse the date parts to integers
        var parts = dateString.split("-");
        var day = parseInt(parts[1], 10);
        var month = parseInt(parts[0], 10);
        var year = parseInt(parts[2], 10);
        // Check the ranges of month and year
        if (year < 1000 || year > 3000 || month === 0 || month > 12) {
            return false;
        }
        var monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
        // Adjust for leap years
        if (year % 400 == 0 || (year % 100 !== 0 && year % 4 === 0)) {
            monthLength[1] = 29;
        }
        // Check the range of the day
        return day > 0 && day <= monthLength[month - 1];
    };
    QuestionsComponent.prototype.formatDate = function (date) {
        var d = new Date(date);
        var month = "" + (d.getMonth() + 1);
        var day = "" + d.getDate();
        var year = d.getFullYear();
        if (month.length < 2) {
            month = "0" + month;
        }
        if (day.length < 2) {
            day = "0" + day;
        }
        return [month, day, year].join("-");
    };
    QuestionsComponent.prototype.checkErrorIninspectedDate = function (fromDate) {
        // if(null !== fromDate){
        this.inspectedDateformatted = moment(fromDate)
            .format("MM-DD-YYYY")
            .toString();
        this.inspectedDateError = "";
        if (this.inspectedDateformatted.length === 0) {
            return this.inspectedDateError;
        }
        if (this.inspectedDateformatted.length !== 10) {
            this.inspectedDateError = "* Enter valid Inspected Date";
        }
        if (!this.validateDate(this.inspectedDateformatted)) {
            this.inspectedDateError = "Invalid Date Format";
        }
        var date = this.inspectedDateformatted.split("-");
        if (date.length !== 3 ||
            date[0].length !== 2 ||
            date[1].length !== 2 ||
            date[2].length !== 4) {
            this.inspectedDateError = " Enter valid Inspected Date";
        }
        else if (!moment(this.inspectedDateformatted, "MM-DD-YYYY").isValid()) {
            this.inspectedDateError = " Enter valid Inspected Date";
        }
        else if (moment(this.inspectedDateformatted).isAfter(moment(new Date()))) {
            this.inspectedDateError = " Future Inspected Date is not allowed";
        }
        return this.inspectedDateError;
        // }
    };
    QuestionsComponent.prototype.validateDate = function (inspectionDueDate) {
        var dateformat = /^(0?[1-9]|1[0-2])[\-](0?[1-9]|[1-2][0-9]|3[01])[\-]\d{4}$/;
        // Match the date format through regular expression
        if (inspectionDueDate.match(dateformat)) {
            var operator = inspectionDueDate.split("-");
            // Extract the string into month, date and year
            var datepart = [];
            if (operator.length > 1) {
                datepart = inspectionDueDate.split("-");
            }
            var month = parseInt(datepart[0]);
            var day = parseInt(datepart[1]);
            var year = parseInt(datepart[2]);
            // Create list of days of a month
            var ListofDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
            if (year < 1000 || year > 3000 || month === 0 || month > 12) {
                return "";
            }
            if (month == 1 || month > 2) {
                if (day > ListofDays[month - 1]) {
                    ///This check is for Confirming that the date is not out of its range
                    return "";
                }
            }
            else if (month == 2) {
                var leapYear = false;
                if ((!(year % 4) && year % 100) || !(year % 400)) {
                    leapYear = true;
                }
                if (leapYear == false && day >= 29) {
                    return "";
                }
                else if (leapYear == true && day > 29) {
                    return "";
                }
            }
        }
        else {
            return "";
        }
        return inspectionDueDate;
    };
    QuestionsComponent.prototype.downloadImage = function (image) {
        var fileURL = image, fileName = image.split("/").pop();
        // for non-IE
        if (!window.ActiveXObject) {
            var save = document.createElement("a");
            save.href = fileURL;
            save.target = "_blank";
            save.download = fileName || "unknown";
            var evt = new MouseEvent("click", {
                view: window,
                bubbles: true,
                cancelable: false,
            });
            save.dispatchEvent(evt);
            (window.URL || window.webkitURL).revokeObjectURL(save.href);
        }
        // for IE < 11
        else if (!!window.ActiveXObject && document.execCommand) {
            var _window = window.open(fileURL, "_blank");
            _window.document.close();
            _window.document.execCommand("SaveAs", true, fileName || fileURL);
            _window.close();
        }
    };
    QuestionsComponent.prototype.checkvalueforSerNum = function (data, data2) {
        if (data2.question === "Old Ser#") {
            if (this.replacementValue) {
                return true;
            }
            return false;
        }
        return true;
    };
    QuestionsComponent.prototype.installCheckBox = function ($event) {
        if ($event && $event.value === "Replacement") {
            this.replacementValue = true;
        }
        else {
            this.replacementValue = false;
        }
    };
    QuestionsComponent.prototype.changeofDownStream = function (event) {
        if (event.value === "DCVA") {
            this.qtd[this.findQuestionId("Downstream Process:")] = "DCVA";
            this.downstreamOthersValue = false;
            this.qtd[this.findQuestionId("Others")] = " ";
        }
        else if (event.value === "RPBA") {
            this.qtd[this.findQuestionId("Downstream Process:")] = "RPBA";
            this.downstreamOthersValue = false;
            this.qtd[this.findQuestionId("Others")] = " ";
        }
        else if (event.value === "PVBA") {
            this.qtd[this.findQuestionId("Downstream Process:")] = "PVBA";
            this.downstreamOthersValue = false;
            this.qtd[this.findQuestionId("Others")] = " ";
        }
        else if (event.value === "OTHER") {
            this.qtd[this.findQuestionId("Downstream Process:")] = "OTHERS";
            this.downstreamOthersValue = true;
        }
    };
    QuestionsComponent.prototype.otherValues = function (event) {
        this.downStreamOthers = event.value;
    };
    QuestionsComponent.prototype.getErrorMessage = function () {
        return "";
    };
    QuestionsComponent.prototype.manageFireAndSmokeDamperQuestions = function () {
        var _this = this;
        var err = Object.keys(this.qtd).filter(function (x) { return x.indexOf("firesmokedamper") > 0 && !_this.qtd[x] && x.split("~")[0] != _this.findQuestionId("Comments:"); });
        var binderQuestionId = this.findQuestionId("Binder left on-site?");
        if (err.length > 0 || !this.qtd[binderQuestionId])
            return false;
        Object.keys(this.qtd).forEach(function (key) {
            if (key.indexOf("firesmokedamper") > 0) {
                var _questionId = key.split("~")[0];
                var _index = key.split("~")[2];
                var _answer = _this.qtd[key];
                if (_this.qtd[_questionId]) {
                    _this.qtd[_questionId][_index] = _answer ? _answer : null;
                }
                else {
                    var arr = [];
                    arr[_index] = _answer ? _answer : null;
                    _this.qtd[_questionId] = arr;
                }
                _this.qtd[_questionId] = Array.from(_this.qtd[_questionId], function (item) { return item || ''; });
                delete _this.qtd[key];
            }
        });
        Object.keys(this.qtd).forEach(function (key) {
            if (_this.qtd[key] instanceof Array) {
                _this.qtd[key] = _this.qtd[key].join('~');
            }
        });
        return true;
    };
    QuestionsComponent.prototype.onSubmit = function () {
        var _this = this;
        this.errorMessage = [];
        this.submitted = true;
        if (this.photo1Error || this.photo2Error || this.photo3Error || this.photo4Error || this.imageUploadError || this.imageUploadErrorSimplified || this.imageUploadError2 || this.imageUploadError3 || this.imageUploadError4) {
            alert('Please upload valid pictures to proceed further.');
            return;
        }
        if (this.assetTypeid === "9") {
            if (this.photo1 && !this.photo1Error) {
                this.testForm.controls['name-photo1'].setErrors(null);
            }
            if (this.photo2 && !this.photo2Error) {
                this.testForm.controls['name-photo2'].setErrors(null);
            }
            if (this.photo3 && !this.photo3Error) {
                this.testForm.controls['name-photo3'].setErrors(null);
            }
        }
        var error = false;
        if (this.assetTypeid === "17" &&
            this.inspectionResult &&
            this.inspectionResult.questionModelVersion &&
            this.inspectionResult.questionModelVersion > 4) {
            var response = this.manageFireAndSmokeDamperQuestions();
            if (!response) {
                this.inspectionService.setErrorInFireAndDamper(true);
                this.errorMessage.push("* Please enter missing damper identifications");
                error = true;
            }
            else {
                this.inspectionService.setErrorInFireAndDamper(false);
            }
        }
        if (this.getDownstreamValue() === "OTHER" &&
            this.qtd[this.findQuestionId("Others")] &&
            this.qtd[this.findQuestionId("Others")] === " ") {
            if (this.errorMessage.indexOf("* Please enter a valid value for Downstream") < 0) {
                this.errorMessage.push("* Please enter a valid value for Downstream");
                document.getElementById("anserText_1223").focus();
            }
        }
        else {
            this.errorMessage.splice(this.errorMessage.indexOf("* Please enter a valid value for Downstream"), 1);
        }
        if (!this.checkbox_authority) {
            if (this.errorMessage.indexOf("* Please certify the above inspection") < 0) {
                this.errorMessage.push("* Please certify the above inspection");
            }
        }
        else {
            if (this.errorMessage.indexOf("* Please certify the above inspection") >= 0) {
                this.errorMessage.splice(this.errorMessage.indexOf("* Please certify the above inspection"), 1);
            }
        }
        if (!this.systemStatus) {
            if (this.errorMessage.indexOf("* Please enter the system status") < 0) {
                this.errorMessage.push("* Please enter the system status");
            }
        }
        else {
            if (this.errorMessage.indexOf("* Please enter the system status") >= 0) {
                this.errorMessage.splice(this.errorMessage.indexOf("* Please enter the system status"), 1);
            }
        }
        if (!this.testForm.valid) {
            if (this.errorMessage.indexOf("* Please answer all the questions") < 0) {
                this.errorMessage.push("* Please answer all the questions");
            }
        }
        else {
            if (this.errorMessage.indexOf("* Please answer all the questions") >= 0) {
                this.errorMessage.splice(this.errorMessage.indexOf("* Please answer all the questions"), 1);
            }
        }
        if (this.invalidAnswers.length > 0) {
            if (this.invalidAnswers.find(function (x) { return x.type === "date"; })) {
                if (this.errorMessage.indexOf("* Please enter a valid date") < 0) {
                    this.errorMessage.push("* Please enter a valid date");
                }
            }
            else {
                if (this.errorMessage.indexOf("* Please enter a valid date") >= 0) {
                    this.errorMessage.splice(this.errorMessage.indexOf("* Please enter a valid date"), 1);
                }
            }
            if (this.invalidAnswers.find(function (x) { return x.type === "Futuredate"; })) {
                if (this.errorMessage.indexOf("Future Inspected Date is not allowed") < 0) {
                    this.errorMessage.push("Future Inspected Date is not allowed");
                }
            }
            else {
                if (this.errorMessage.indexOf("Future Inspected Date is not allowed") >= 0) {
                    this.errorMessage.splice(this.errorMessage.indexOf("Future Inspected Date is not allowed"), 1);
                }
            }
            if (this.invalidAnswers.find(function (x) { return x.type === "text"; })) {
                if (this.errorMessage.indexOf("* Please enter a valid value") < 0) {
                    this.errorMessage.push("* Please enter a valid value");
                }
            }
            else {
                if (this.errorMessage.indexOf("* Please enter a valid value") >= 0) {
                    this.errorMessage.splice(this.errorMessage.indexOf("* Please enter a valid value"), 1);
                }
            }
        }
        if (this.inspectedDateError.length > 0) {
            this.errorMessage.push(this.inspectedDateError);
            var invalidElement = this.el.nativeElement.querySelector("#inspectedDateId");
            if (invalidElement) {
                invalidElement.focus();
                invalidElement.scrollIntoView();
            }
        }
        else if (this.errorMessage.indexOf(this.inspectedDateError) > -1) {
            this.errorMessage.splice(this.errorMessage.indexOf(this.inspectedDateError), 1);
        }
        var a = this.questions.filter(function (elem) { return elem.questions.question === "FIRE DEPARTMENT CONNECTION"; });
        if (a.length > 0) {
            this.removeSubQuestions(a);
        }
        var questionsDTO = [];
        var tableObjects = [];
        if (this.assetTypeid === "15") {
            Object.keys(this.qtd).forEach(function (key) {
                if (key.indexOf("~") > 0) {
                    delete _this.qtd[key];
                }
            });
        }
        // Checkbox type question
        Object.keys(this.qtd).map(function (key) {
            if (key.indexOf("*o*") !== -1) {
                var originalKey = key.split("*")[0];
                if (_this.qtd.hasOwnProperty(originalKey)) {
                    delete _this.qtd[originalKey];
                }
            }
        });
        Object.keys(this.qtd).forEach(function (key) {
            /* Table Type Questions */
            if (key.indexOf("checkbox") < 0 &&
                key.indexOf("~") > 0 &&
                // clean agent & fire alarm table questions not required.
                _this.assetTypeid !== "10" &&
                _this.assetTypeid !== "3") {
                var answer = { index: "", max: "", value: "" };
                answer.index = key.split("~")[2];
                if (key.indexOf("standpipe") > 0) {
                    answer.max = _this.inspectionService.getStandPipeRows() || 1;
                }
                else if (key.indexOf("hose") > 0) {
                    answer.max = _this.inspectionService.getHoseCount() || 1;
                }
                else {
                    answer.max = key.split("~")[3];
                }
                // answer.max = key.indexOf('standpipe') > 0 ? (this.inspectionService.getStandPipeRows() || 1) :
                //   key.indexOf('hose') > 0 ? this.inspectionService.getHoseCount() : key.split('~')[3];
                answer.value = _this.qtd[key];
                var questionId_1 = key.split("~")[0];
                if (tableObjects.filter(function (x) { return x.question === questionId_1; }).length === 0) {
                    tableObjects.push({ question: questionId_1, answer: [answer] });
                }
                else {
                    tableObjects
                        .filter(function (x) { return x.question === questionId_1; })[0]
                        .answer.push(answer);
                }
            }
            if (key.indexOf("checkbox") < 0 &&
                key.indexOf("o") < 0 &&
                key.indexOf("~") < 0) {
                var value = _this.qtd[key];
                var updatedValue = void 0;
                if (value && typeof value === "object" && value.length > 0) {
                    updatedValue = value[0];
                }
                else {
                    updatedValue = value;
                }
                questionsDTO.push({
                    answer: updatedValue,
                    questionId: key,
                });
            }
            if (key.indexOf("o") > 0 &&
                key.indexOf("checkbox") < 0 &&
                key.indexOf("hose") < 0) {
                if (_this.qtd[key]) {
                    var value = key.split("*")[2];
                    var newKey = key.split("*")[0];
                    questionsDTO.push({
                        answer: value,
                        questionId: newKey,
                    });
                }
            }
            if (key.indexOf("checkbox") >= 0) {
                var value = _this.qtd[key];
                var newKey_1;
                if (value) {
                    newKey_1 = key.substring(0, key.length - 8);
                    _this.qtd[newKey_1] = "N/A";
                    if (questionsDTO.find(function (x) { return x.questionId === newKey_1; })) {
                        questionsDTO.find(function (x) { return x.questionId === newKey_1; }).answer = "N/A";
                    }
                    else {
                        questionsDTO.push({
                            answer: "N/A",
                            questionId: newKey_1,
                        });
                    }
                }
            }
        });
        if (tableObjects.length > 0) {
            tableObjects.forEach(function (x) {
                var finalAnswer = "";
                var _loop_1 = function (i) {
                    if (x.answer.find(function (y) { return +y.index === i; })) {
                        finalAnswer =
                            finalAnswer + "~" + x.answer.find(function (y) { return +y.index === i; }).value;
                    }
                    else {
                        finalAnswer = finalAnswer + "~" + " ";
                    }
                };
                for (var i = 0; i < x.answer[0].max; i++) {
                    _loop_1(i);
                }
                if (questionsDTO.find(function (y) { return y.questionId === x.question; })) {
                    questionsDTO.find(function (y) { return y.questionId === x.question; }).answer =
                        finalAnswer.substring(1);
                }
                else {
                    questionsDTO.push({
                        answer: finalAnswer.substring(1),
                        questionId: x.question,
                    });
                }
            });
        }
        Object.keys(this.images).forEach(function (key) {
            var answerImages = [];
            var images = _this.images[key];
            images.forEach(function (x) {
                answerImages.push({ imageContent: x, imageName: " " });
            });
            if (_this.imageUploadQuestions.indexOf(+key) >= 0) {
                if (questionsDTO.find(function (x) { return x.questionId === key; })) {
                    questionsDTO.find(function (x) { return x.questionId === key; }).answerImages = images;
                }
                else {
                    if (Number(_this.assetTypeid) === 11) {
                        if (questionsDTO.length > 0) {
                            questionsDTO.push({ questionId: key, answerImages: images });
                        }
                        else {
                            questionsDTO = [{ questionId: key, answerImages: images }];
                        }
                    }
                }
            }
            else {
                if (questionsDTO.length > 0) {
                    questionsDTO.push({ questionId: key, answerImages: images });
                }
                else {
                    questionsDTO = [{ questionId: key, answerImages: images }];
                }
            }
        });
        questionsDTO.map(function (elem) {
            if (elem.answer !== null &&
                elem.answer &&
                (elem.answer.hasOwnProperty("unitsInBuilding") ||
                    elem.answer.hasOwnProperty("unitsTested") ||
                    elem.answer.hasOwnProperty("resultsAcceptable"))) {
                elem.tableAnswers = elem.answer;
                delete elem.answer;
            }
        });
        var notAllQuestionsHaveBeenAnswered = false;
        this.questionsDTO = questionsDTO;
        this.checkBoxQuestions.forEach(function (x) {
            if (!_this.questionsDTO.find(function (y) { return +y.questionId === x; })) {
                notAllQuestionsHaveBeenAnswered = true;
            }
        });
        if (this.inspectionDataforSecondPage.inspectionType !== "SIMPLIFIED_REPORT" &&
            notAllQuestionsHaveBeenAnswered) {
            if (this.errorMessage.indexOf("* Please answer all the questions") < 0) {
                this.errorMessage.push("* Please answer all the questions");
            }
        }
        else {
            if (this.testForm.valid) {
                if (this.errorMessage.indexOf("* Please answer all the questions") >= 0) {
                    this.errorMessage.splice(this.errorMessage.indexOf("* Please answer all the questions"), 1);
                }
            }
            if (this.inspectionDataforSecondPage.inspectionType === "SIMPLIFIED_REPORT") {
                if (!this.imageSimplified ||
                    (this.imageSimplified && this.imageSimplified.trim() === "")) {
                    if (this.errorMessage.indexOf("* Please upload a document") < 0) {
                        this.errorMessage.push("* Please upload a document");
                    }
                }
                else {
                    if (this.errorMessage.indexOf("* Please upload a document") >= 0) {
                        this.errorMessage.splice(this.errorMessage.indexOf("* Please upload a document"), 1);
                    }
                }
            }
            if (this.errorMessage.length === 0 && !error) {
                this.inspectionResult.inspectionAttachment = [];
                this.emitErrorMessages.emit([]);
                if (confirm("Inspection report will be saved in the system. Do you want to save it?")) {
                    this.questionReportService.clearReportData();
                    this.inspectionResult.authorityRemarks = "";
                    /* TODO on POST */
                    this.inspectionResult.id = null;
                    this.inspectionResult.inspectedDate =
                        this.inspectedDateformatted.split("-")[2] +
                            "-" +
                            this.inspectedDateformatted.split("-")[0] +
                            "-" +
                            this.inspectedDateformatted.split("-")[1] +
                            "T00:00:00.000Z";
                    //  if (this.inspectionDataforSecondPage.inspectionType === 'SIMPLIFIED_REPORT') {
                    if (this.fileNameSimplified) {
                        this.inspectionResult.inspectionAttachment.push({
                            attachment: this.imageSimplified,
                            imageName: this.fileNameSimplified,
                            imagePlacement: "Simple",
                        });
                    }
                    // }
                    if (this.fileName !== "" && this.fileName != undefined) {
                        if (this.image) {
                            this.inspectionResult.inspectionAttachment.push({
                                attachment: this.image,
                                imageName: this.fileName,
                                imagePlacement: "Main",
                            });
                        }
                    }
                    if (this.fileName4 !== "" && this.fileName4 != undefined) {
                        if (this.image4) {
                            this.inspectionResult.inspectionAttachment.push({
                                attachment: this.image4,
                                imageName: this.fileName4,
                                imagePlacement: "Second",
                            });
                        }
                    }
                    if (this.fileName2 !== "" && this.fileName2 != undefined) {
                        if (this.image2) {
                            this.inspectionResult.inspectionAttachment.push({
                                attachment: this.image2,
                                imageName: this.fileName2,
                                imagePlacement: "Third",
                            });
                        }
                    }
                    if (this.fileName3 !== "" && this.fileName3 != undefined) {
                        if (this.image3) {
                            this.inspectionResult.inspectionAttachment.push({
                                attachment: this.image3,
                                imageName: this.fileName3,
                                imagePlacement: "Fourth",
                            });
                        }
                    }
                    if (this.photo1) {
                        this.inspectionResult.inspectionAttachment.push({
                            attachment: this.photo1,
                            imageName: this.photo1Name,
                        });
                    }
                    if (this.photo2) {
                        this.inspectionResult.inspectionAttachment.push({
                            attachment: this.photo2,
                            imageName: this.photo2Name,
                        });
                    }
                    if (this.photo3) {
                        this.inspectionResult.inspectionAttachment.push({
                            attachment: this.photo3,
                            imageName: this.photo3Name,
                        });
                    }
                    if (this.photo4) {
                        this.inspectionResult.inspectionAttachment.push({
                            attachment: this.photo4,
                            imageName: this.photo4Name,
                        });
                    }
                    this.inspectionResult.inspectionRemarks = this.additionalRemarks;
                    this.inspectionResult.isInspectorCustomArea =
                        this.additional === "yes" ? true : false;
                    this.inspectionResult.systemStatus = this.systemStatus;
                    if (this.assetTypeid === '9' && this.inspectionResult.inspectionAttachment.length < 3) {
                        alert("Please upload mandatory pictures to proceed further.");
                        return;
                    }
                    if (this.inspectionReportID === "false") {
                        this.disableSave = true;
                        var apiPayload = {
                            inspectionResultDto: this.inspectionResult,
                            answerDtoList: this.questionsDTO
                        };
                        this.loggerService.pushToServer({
                            Line: "questions.component.ts:1159",
                            API: "/inspection/answer/" + this.assetId,
                            Data: { apiPayload: apiPayload },
                        });
                        this.subscriptions.push(this.inspectionService.sendConsolidatedInspectionResultAndAnswer(this.assetId, apiPayload).subscribe(function (result) {
                            if (result) {
                                _this.disableSave = false;
                                // alert(result);
                            }
                            else if (result === false) {
                                alert("Error occurred while saving inspection report in the system. Please re-fill and save the report. If same issue occurs again, please contact support@tegrisfire.com.");
                            }
                            _this.redirectDashboard();
                        }, function (error) {
                            if (error) {
                                alert(error.error.message);
                            }
                        }));
                        // this.subscriptions.push(this.inspectionService.postInspectionResult(this.inspectionResult, this.assetId).subscribe((result) => {
                        //   if (result) {
                        //     this.disableSave = false;
                        //     this.subscriptions.push(this.inspectionService.postAnswer(result.id, this.questionsDTO).subscribe((res) => {
                        //       if (res) {
                        //         alert(res);
                        //       }
                        //       this.redirectDashboard();
                        //     }, (error) => {
                        //       if (error) {
                        //         alert(error.error.message);
                        //       }
                        //     }));
                        //   }
                        // }, (error) => {
                        //   if (error) {
                        //     alert(error.error.message);
                        //   }
                        // }));
                    }
                    else {
                        this.disableSave = true;
                        this.inspectionResult.id = this.inspectionDataforSecondPage.id;
                        var apiPayload = {
                            inspectionResultDto: this.inspectionResult,
                            answerDtoList: this.questionsDTO
                        };
                        this.loggerService.pushToServer({
                            Line: "questions.component.ts:1207",
                            API: "/inspection/answer/" + this.assetId,
                            Data: apiPayload,
                        });
                        this.subscriptions.push(this.inspectionService.sendConsolidatedInspectionResultAndAnswer(this.assetId, apiPayload).subscribe(function (result) {
                            if (result) {
                                _this.disableSave = false;
                                // alert(result);
                            }
                            else if (result === false) {
                                alert("Error occurred while saving inspection report in the system. Please re-fill and save the report. If same issue occurs again, please contact support@tegrisfire.com.");
                            }
                            _this.redirectDashboard();
                        }, function (error) {
                            if (error) {
                                alert(error.error.message);
                            }
                        }));
                        // this.subscriptions.push(this.inspectionService.putInspectionResult(this.inspectionResult, this.assetId).subscribe((result) => {
                        //   if (result) {
                        //     this.disableSave = false;
                        //     this.subscriptions.push(this.inspectionService.postAnswer(result.id, this.questionsDTO).subscribe((res) => {
                        //       if (res) {
                        //         alert(res);
                        //       }
                        //       this.redirectDashboard();
                        //     }, (error) => {
                        //       if (error) {
                        //         alert(error.error.message);
                        //       }
                        //     }));
                        //   }
                        // }, (error) => {
                        //   if (error) {
                        //     alert(error.error.message);
                        //   }
                        // }));
                    }
                }
            }
            else {
                this.emitErrorMessages.emit(this.errorMessage);
                alert("* Please answer all the questions.");
            }
        }
    };
    QuestionsComponent.prototype.subQuestionsCondition = function (question) {
        if (this.inspectionDataforSecondPage &&
            this.inspectionDataforSecondPage.accessSpecifier === "CUSTOM") {
            return false;
        }
        return ((question.questions.subquestion &&
            question.questions.subquestion &&
            question.questions.subquestion.questionType &&
            question.questions.subquestion.questionType !== "Custom") ||
            (question.questions.hasDependents &&
                !question.questions.dependencyValue &&
                this.qtd[question.questions.questionId] === "Yes") ||
            (question.questions.question === "CLASS I" &&
                this.qtd[this.findQuestionId("Standpipe Class:")] === "class1") ||
            (question.questions.question === "CLASS II" &&
                this.qtd[this.findQuestionId("Standpipe Class:")] === "class2") ||
            (question.questions.question === "CLASS III" &&
                this.qtd[this.findQuestionId("Standpipe Class:")] === "class3") ||
            this.printBlank);
    };
    QuestionsComponent.prototype.removeSubQuestions = function (question) {
        var _this = this;
        if (this.qtd[question[0].questions.questionId] === "No") {
            question[0].questions.subquestion.map(function (subQ) {
                _this.qtd[subQ.questionId] = null;
                if (_this.images[subQ.questionId]) {
                    delete _this.images[subQ.questionId];
                }
            });
        }
    };
    QuestionsComponent.prototype.removeImage = function (questionId, index, uploadTwice) {
        if (this.images[questionId] && this.images[questionId].length > 0) {
            this.images[questionId].splice(index, 1);
        }
        if (!uploadTwice &&
            this.images[questionId] &&
            this.images[questionId].length < 3) {
            if (this.uploadError[questionId] &&
                this.uploadError[questionId].indexOf("Cannot upload more than 3 images") >= 0) {
                this.uploadError[questionId].splice(this.uploadError[questionId].indexOf("Cannot upload more than 3 images"), 1);
            }
        }
        if (uploadTwice &&
            this.images[questionId] &&
            this.images[questionId].filter(function (x) { return x.imagePlacement === uploadTwice; })
                .length < 3) {
            if (this.uploadError[questionId] &&
                this.uploadError[questionId][uploadTwice] &&
                this.uploadError[questionId][uploadTwice].indexOf("Cannot upload more than 3 images") >= 0) {
                this.uploadError[questionId][uploadTwice].splice(this.uploadError[questionId][uploadTwice].indexOf("Cannot upload more than 3 images"), 1);
            }
        }
        if (this.images[questionId].length === 0) {
            this.uploadError[questionId] = [];
            this.uploadError[questionId].length = 0;
            if (this.uploadError[questionId][uploadTwice]) {
                this.uploadError[questionId][uploadTwice].length = 0;
            }
        }
        if (this.images[questionId] &&
            this.images[questionId].filter(function (x) { return x.imagePlacement === uploadTwice; })
                .length === 0) {
            if (uploadTwice === "Front") {
                this.inspectionDataforSecondPage.noFrontImage = true;
            }
            else {
                this.inspectionDataforSecondPage.noBackImage = true;
            }
        }
    };
    QuestionsComponent.prototype.uploadFile = function (questonid, event, files, uploadTwice, countOfImages) {
        var _this = this;
        if (!this.images) {
            this.images = {};
        }
        if (!this.images[questonid]) {
            this.images[questonid] = [];
        }
        if (this.images[questonid].length > 0 && countOfImages === 1) {
            alert("One image is already uploaded. Please delete the other image to upload new image.");
            return;
        }
        if (uploadTwice &&
            this.images[questonid] &&
            !this.images[questonid][uploadTwice]) {
            this.images[questonid][uploadTwice] = [];
        }
        if (!this.uploadError[questonid]) {
            this.uploadError[questonid] = [];
        }
        if (uploadTwice &&
            this.uploadError[questonid] &&
            !this.uploadError[questonid][uploadTwice]) {
            this.uploadError[questonid][uploadTwice] = [];
        }
        var image = files.item(0);
        var fileName = image.name;
        // this.fileName = image.name;
        // this.initialValue = this.fileName;
        if (image.size < 1097152) {
            if (image.type.toLowerCase() === "image/gif" ||
                image.type.toString() === "image/jpeg") {
                this.uploadError[questonid].length = 0;
                var reader_1 = new FileReader();
                reader_1.readAsDataURL(image);
                reader_1.onload = function () {
                    var base64logoImage = reader_1.result.toString();
                    // tslint:disable-next-line: no-shadowed-variable
                    var image = reader_1.result.toString();
                    if (!uploadTwice) {
                        if (_this.images[questonid].length < 3) {
                            _this.images[questonid].push({
                                imageContent: image,
                                imageName: fileName,
                            });
                        }
                        else {
                            _this.uploadError[questonid].push("Cannot upload more than 3 images");
                        }
                    }
                    else {
                        if (_this.images[questonid] &&
                            _this.images[questonid].filter(function (x) { return x.imagePlacement === uploadTwice; }).length < 3) {
                            _this.images[questonid].push({
                                imageContent: image,
                                imageName: fileName,
                                imagePlacement: uploadTwice,
                            });
                        }
                        else {
                            _this.uploadError[questonid][uploadTwice].push("Cannot upload more than 3 images");
                        }
                    }
                    event.value = "";
                };
                reader_1.onerror = function (error) {
                    _this.loggerService.log("Error on Loading Image", error);
                    if (!uploadTwice) {
                        _this.uploadError[questonid].push("Error while Loading File");
                    }
                    else {
                        _this.uploadError[questonid][uploadTwice].push("Error while Loading File");
                    }
                    // this.imageUploadError = "Error while Loading File"
                };
            }
            else {
                if (!uploadTwice) {
                    this.uploadError[questonid].push("Invalid File Type");
                }
                else {
                    this.uploadError[questonid][uploadTwice].push("Invalid File Type");
                }
                // this.imageUploadError = "Invalid File Type"
            }
        }
        else if (image.size > 1097152) {
            if (!uploadTwice) {
                this.uploadError[questonid].push("File Size is greater than 1MB");
            }
            else {
                this.uploadError[questonid][uploadTwice].push("File Size is greater than 1MB");
            }
            // this.imageUploadError = ""
        }
        else if (image.type.toLowerCase() !== "gif" ||
            image.type.toString() !== "jpg") {
            if (!uploadTwice) {
                this.uploadError[questonid].push("Invalid File Type");
            }
            else {
                this.uploadError[questonid][uploadTwice].push("Invalid File Type");
            }
        }
    };
    QuestionsComponent.prototype.removeFile = function () {
        this.fileName = "";
        this.imageUploadError = "";
    };
    QuestionsComponent.prototype.removeFileEmergencyRes = function (event) {
        if (event.id === "removeImg4") {
            this.fileName4 = "";
            this.imageUploadError4 = "";
        }
        else if (event.id === "removeImg2") {
            this.fileName2 = "";
            this.imageUploadError2 = "";
        }
        else if (event.id === "removeImg3") {
            this.fileName3 = "";
            this.imageUploadError3 = "";
        }
    };
    QuestionsComponent.prototype.uploadFileMain = function (event, files) {
        var _this = this;
        var image = files.item(0);
        this.fileName = image.name;
        if ((image.size < 1097152 && this.assetTypeid !== "14") ||
            (image.size <= 5242880 && this.assetTypeid === "14")) {
            this.imageUploadError = "";
            if (image.type.toLowerCase() === "image/gif" ||
                image.type.toString() === "image/jpeg" ||
                image.type.toString() === "application/pdf") {
                var reader_2 = new FileReader();
                reader_2.readAsDataURL(image);
                reader_2.onload = function () {
                    _this.base64logoImage = reader_2.result.toString();
                    _this.image = reader_2.result.toString();
                    event.value = "";
                };
                reader_2.onerror = function (error) {
                    _this.loggerService.log("Error on Loading Image", error);
                    _this.imageUploadError = "Error while Loading File";
                };
            }
            else {
                this.imageUploadError = "Invalid File Type";
            }
        }
        else if (image.size > 1097152 && this.assetTypeid !== "14") {
            this.imageUploadError = "File Size is greater than 1MB";
        }
        else if (image.size > 5242880 && this.assetTypeid === "14") {
            this.imageUploadError = "File Size is greater than 5MB";
        }
        else if (image.type.toLowerCase() !== "gif" ||
            image.type.toString() !== "jpg" ||
            image.type.toString() !== "application/pdf") {
            this.imageUploadError = "Invalid File Type";
        }
    };
    QuestionsComponent.prototype.uploadFileMainEmergencyResp = function (event, files) {
        var _this = this;
        var image = files.item(0);
        var filenum = event.id;
        if (event.id === "img4") {
            this.fileName4 = image.name;
        }
        else if (event.id === "img2") {
            this.fileName2 = image.name;
        }
        else if (event.id === "img3") {
            this.fileName3 = image.name;
        }
        if (image.size <= 5242880) {
            if (image.type.toLowerCase() === "image/gif" ||
                image.type.toString() === "image/jpeg" ||
                image.type.toString() === "application/pdf") {
                if (event.id === "img4") {
                    this.imageUploadError4 = "";
                }
                else if (event.id === "img2") {
                    this.imageUploadError2 = "";
                }
                else if (event.id === "img3") {
                    this.imageUploadError3 = "";
                }
                var reader_3 = new FileReader();
                reader_3.readAsDataURL(image);
                reader_3.onload = function () {
                    _this.base64logoImage = reader_3.result.toString();
                    if (event.id === "img2") {
                        _this.image2 = reader_3.result.toString();
                    }
                    if (event.id === "img3") {
                        _this.image3 = reader_3.result.toString();
                    }
                    if (event.id === "img4") {
                        _this.image4 = reader_3.result.toString();
                    }
                    event.value = "";
                };
                reader_3.onerror = function (error) {
                    _this.loggerService.log("Error on Loading Image", error);
                    _this.imageUploadError = "Error while Loading File";
                };
            }
            else {
                if (event.id === "img2") {
                    this.imageUploadError2 = "Invalid File Type";
                }
                else if (event.id === "img3") {
                    this.imageUploadError3 = "Invalid File Type";
                }
                else if (event.id === "img4") {
                    this.imageUploadError4 = "Invalid File Type";
                }
            }
        }
        else if (image.size > 5242880) {
            if (event.id === "img2") {
                this.imageUploadError2 = "File Size is greater than 5MB";
            }
            else if (event.id === "img3") {
                this.imageUploadError3 = "File Size is greater than 5MB";
            }
            else if (event.id === "img4") {
                this.imageUploadError4 = "File Size is greater than 5MB";
            }
        }
        else if (image.type.toLowerCase() !== "gif" ||
            image.type.toString() !== "jpg" ||
            image.type.toString() !== "application/pdf") {
            if (event.id === "img2") {
                this.imageUploadError2 = "Invalid File Type";
            }
            else if (event.id === "img3") {
                this.imageUploadError3 = "Invalid File Type";
            }
            else if (event.id === "img4") {
                this.imageUploadError4 = "Invalid File Type";
            }
        }
    };
    QuestionsComponent.prototype.uploadPhoto1 = function (event, files) {
        var _this = this;
        var image = files.item(0);
        this.photo1Name = image.name;
        if (image.size < 1097152) {
            if (image.type.toLowerCase() === "image/gif" ||
                image.type.toString() === "image/jpeg") {
                this.photo1Error = "";
                var reader_4 = new FileReader();
                reader_4.readAsDataURL(image);
                reader_4.onload = function () {
                    _this.photo1 = reader_4.result.toString();
                    event.value = "";
                };
                reader_4.onerror = function (error) {
                    _this.loggerService.log("Error on Loading Image", error);
                    _this.photo1Error = "Error while Loading File";
                };
            }
            else {
                this.photo1Error = "Invalid File Type";
            }
        }
        else if (image.size > 1097152) {
            this.photo1Error = "File Size is greater than 1MB";
        }
        else if (image.type.toLowerCase() !== "gif" ||
            image.type.toString() !== "jpg") {
            this.photo1Error = "Invalid File Type";
        }
    };
    QuestionsComponent.prototype.uploadPhoto2 = function (event, files) {
        var _this = this;
        var image = files.item(0);
        this.photo2Name = image.name;
        if (image.size < 1097152) {
            if (image.type.toLowerCase() === "image/gif" ||
                image.type.toString() === "image/jpeg") {
                this.photo2Error = "";
                var reader_5 = new FileReader();
                reader_5.readAsDataURL(image);
                reader_5.onload = function () {
                    _this.photo2 = reader_5.result.toString();
                    event.value = "";
                };
                reader_5.onerror = function (error) {
                    _this.loggerService.log("Error on Loading Image", error);
                    _this.photo2Error = "Error while Loading File";
                };
            }
            else {
                this.photo2Error = "Invalid File Type";
            }
        }
        else if (image.size > 1097152) {
            this.photo2Error = "File Size is greater than 1MB";
        }
        else if (image.type.toLowerCase() !== "gif" ||
            image.type.toString() !== "jpg") {
            this.photo2Error = "Invalid File Type";
        }
    };
    QuestionsComponent.prototype.uploadPhoto3 = function (event, files) {
        var _this = this;
        var image = files.item(0);
        this.photo3Name = image.name;
        if (image.size < 1097152) {
            if (image.type.toLowerCase() === "image/gif" ||
                image.type.toString() === "image/jpeg") {
                this.photo3Error = "";
                var reader_6 = new FileReader();
                reader_6.readAsDataURL(image);
                reader_6.onload = function () {
                    _this.photo3 = reader_6.result.toString();
                    event.value = "";
                };
                reader_6.onerror = function (error) {
                    _this.loggerService.log("Error on Loading Image", error);
                    _this.photo3Error = "Error while Loading File";
                };
            }
            else {
                this.photo3Error = "Invalid File Type";
            }
        }
        else if (image.size > 1097152) {
            this.photo3Error = "File Size is greater than 1MB";
        }
        else if (image.type.toLowerCase() !== "gif" ||
            image.type.toString() !== "jpg") {
            this.photo3Error = "Invalid File Type";
        }
    };
    QuestionsComponent.prototype.uploadPhoto4 = function (event, files) {
        var _this = this;
        var image = files.item(0);
        this.photo4Name = image.name;
        if (image.size < 1097152) {
            if (image.type.toLowerCase() === "image/gif" ||
                image.type.toString() === "image/jpeg") {
                this.photo4Error = "";
                var reader_7 = new FileReader();
                reader_7.readAsDataURL(image);
                reader_7.onload = function () {
                    _this.photo4 = reader_7.result.toString();
                    event.value = "";
                };
                reader_7.onerror = function (error) {
                    _this.loggerService.log("Error on Loading Image", error);
                    _this.photo4Error = "Error while Loading File";
                };
            }
            else {
                this.photo4Error = "Invalid File Type";
            }
        }
        else if (image.size > 1097152) {
            this.photo4Error = "File Size is greater than 1MB";
        }
        else if (image.type.toLowerCase() !== "gif" ||
            image.type.toString() !== "jpg") {
            this.photo4Error = "Invalid File Type";
        }
    };
    QuestionsComponent.prototype.removePhoto1 = function () {
        this.photo1Name = "";
        this.photo1 = null;
        this.photo1Error = "";
    };
    QuestionsComponent.prototype.removePhoto2 = function () {
        this.photo2Name = "";
        this.photo2 = null;
        this.photo2Error = "";
    };
    QuestionsComponent.prototype.removePhoto3 = function () {
        this.photo3Name = "";
        this.photo3 = null;
        this.photo3Error = "";
    };
    QuestionsComponent.prototype.removePhoto4 = function () {
        this.photo4Name = "";
        this.photo4 = null;
        this.photo4Error = "";
    };
    QuestionsComponent.prototype.backButtonClicked = function () {
        var baseAsset = {
            assetId: this.assetId.toString(),
            image: this.image,
            inspectedDate: this.inspectedDate ? this.inspectedDate.toString() : null,
            systemStatus: this.systemStatus,
            additional: this.additional,
            additionalRemarks: this.additionalRemarks,
            fileName: this.fileName,
            imageUploadError: this.imageUploadError // Default condition property included
        };
        // Add properties based on assetTypeid
        switch (Number(this.assetTypeid)) {
            case 10:
            case 9:
                Object.assign(baseAsset, {
                    photo1: this.photo1,
                    photo1Name: this.photo1Name,
                    photo1Error: this.photo1Error,
                    photo2: this.photo2,
                    photo2Name: this.photo2Name,
                    photo2Error: this.photo2Error,
                    photo3: this.photo3,
                    photo3Name: this.photo3Name,
                    photo3Error: this.photo3Error
                });
                if (Number(this.assetTypeid) === 10) {
                    Object.assign(baseAsset, {
                        photo4: this.photo4,
                        photo4Name: this.photo4Name,
                        photo4Error: this.photo4Error
                    });
                }
                break;
            case 14:
                Object.assign(baseAsset, {
                    image2: this.image2,
                    fileName2: this.fileName2,
                    imageUploadError2: this.imageUploadError2,
                    image3: this.image3,
                    fileName3: this.fileName3,
                    imageUploadError3: this.imageUploadError3,
                    image4: this.image4,
                    fileName4: this.fileName4,
                    imageUploadError4: this.imageUploadError4,
                });
                break;
        }
        // Save asset data
        this.questionReportService.saveReportData(baseAsset);
        this.inspectionService.setErrorInFireAndDamper(false);
        this.emitErrorMessages.emit([]);
        this.backButton.emit();
    };
    QuestionsComponent.prototype.redirectDashboard = function () {
        this.inspectionService.setErrorInFireAndDamper(false);
        if (this.questionReportService.getReportData() != null) {
            this.questionReportService.clearReportData();
        }
        if (this.router.url.indexOf("fileCabinet") > -1 || this.router.url.indexOf("dashboardAuthority") > -1) {
            this.dialogRef.close();
            return;
        }
        if (!this.addEditProperty && !this.isViewMode) {
            this.router.navigate([
                "user/inspection/searchInspectionProperties/getInspectionAssetsByProperty",
            ], {
                queryParams: {
                    id: this.propertyId,
                    pac: this.pac,
                    isSimplifiedReportEnabled: this.isSimplifiedReportEnabled,
                    isImageUploadEnabled: this.isImageUploadEnabled,
                    fileCabinetRedirect: false,
                },
            });
        }
        else if (this.isViewMode) {
            this.location.back();
        }
        else {
            this.router.navigate(["user/inspection/addEditProperty/getInspectionAssetsByProperty"], {
                queryParams: {
                    id: this.propertyId,
                    pac: this.pac,
                    isSimplifiedReportEnabled: this.isSimplifiedReportEnabled,
                    isImageUploadEnabled: this.isImageUploadEnabled,
                },
            });
        }
    };
    QuestionsComponent.prototype.checkValidity = function (data, type, id, unit, question) {
        if (data !== null && typeof data === "object") {
            data = data[0];
        }
        if (data && data.trim() !== "") {
            var phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
            if (unit) {
                if (type === "Text") {
                    if (data !== "N/A" && data.length < 1) {
                        if (!this.invalidAnswers.find(function (x) { return x.id === id; })) {
                            this.invalidAnswers.push({ id: id, type: "text" });
                        }
                        return false;
                    }
                    else {
                        if (this.invalidAnswers.find(function (x) { return x.id === id; })) {
                            this.invalidAnswers = this.invalidAnswers.filter(function (x) { return x.id !== id; });
                        }
                        return true;
                    }
                }
                if (type === "Date") {
                    if (unit && unit.split("-") && unit.split("-").length === 3) {
                        if (question.indexOf("Last cleaning date (per NFPA standards):") > -1) {
                            if (data != "N/A" &&
                                (!this.validatePastDate(data) || !this.isValidDate(data))) {
                                if (!this.invalidAnswers.find(function (x) { return x.id === id; })) {
                                    this.invalidAnswers.push({ id: id, type: "date" });
                                }
                                return false;
                            }
                            else if (this.validateFutureDate(data)) {
                                if (!this.invalidAnswers.find(function (x) { return x.id === id; })) {
                                    this.invalidAnswers.push({ id: id, type: "Futuredate" });
                                }
                                return false;
                            }
                            else {
                                if (this.invalidAnswers.find(function (x) { return x.id === id; })) {
                                    this.invalidAnswers = this.invalidAnswers.filter(function (x) { return x.id !== id; });
                                }
                                return true;
                            }
                        }
                        else {
                            if (data != "N/A" && !this.isValidDate(data)) {
                                if (!this.invalidAnswers.find(function (x) { return x.id === id; })) {
                                    this.invalidAnswers.push({ id: id, type: "date" });
                                }
                                return false;
                            }
                            else {
                                if (this.invalidAnswers.find(function (x) { return x.id === id; })) {
                                    this.invalidAnswers = this.invalidAnswers.filter(function (x) { return x.id !== id; });
                                }
                                return true;
                            }
                        }
                    }
                    else {
                        if (data != "N/A" && !this.isValidCommertialDate(data)) {
                            if (!this.invalidAnswers.find(function (x) { return x.id === id; })) {
                                this.invalidAnswers.push({ id: id, type: "date" });
                            }
                            return false;
                        }
                        else {
                            if (this.invalidAnswers.find(function (x) { return x.id === id; })) {
                                this.invalidAnswers = this.invalidAnswers.filter(function (x) { return x.id !== id; });
                            }
                            return true;
                        }
                    }
                }
            }
            else {
                if ((question && question.indexOf("Tel#:(Office)") >= 0) ||
                    question.indexOf("(Cell)") >= 0) {
                    if (data && !data.match(phoneRegex)) {
                        this.invalidAnswers.push({ id: id, type: "text" });
                        return false;
                    }
                    else {
                        this.invalidAnswers = this.invalidAnswers.filter(function (x) { return x.id !== id; });
                        return true;
                    }
                }
                if (type === "Text") {
                    return true;
                }
            }
        }
        else {
            return true;
        }
    };
    QuestionsComponent.prototype.validatePastDate = function (date) {
        return moment(date, "MM-DD-YYYY").isBefore(new Date());
    };
    QuestionsComponent.prototype.validateFutureDate = function (date) {
        return moment(date, "MM-DD-YYYY").isAfter(new Date());
    };
    QuestionsComponent.prototype.checkHydrantImage = function (images, position) {
        if (images) {
            return images.find(function (x) { return x["imagePlacement"] === position; })
                ? true
                : false;
        }
        else {
            return false;
        }
    };
    QuestionsComponent.prototype.setValuesOfNA = function (questonID, target, question) {
        if (target.checked) {
            this.qtd[questonID] = "N/A";
        }
        else {
            this.qtd[questonID] = "";
        }
        if (this.invalidAnswers.find(function (x) { return x.id === questonID; })) {
            this.invalidAnswers = this.invalidAnswers.filter(function (x) { return x.id === !questonID; });
        }
        // if (target.checked) {
        //   if (question && question.questions && question.questions.question &&
        //     question.questions.question === 'Weight of 1st CO2 or Nitrogen cartridge:'
        //     || question.questions.question === 'Weight of 2nd CO2 or Nitrogen cartridge:'
        //     || question.questions.question === 'Weight of 3rd CO2 or Nitrogen cartridge:') {
        //     if (!this.sequence) {
        //       this.sequence = [];
        //       this.sequence.push(question.questions.sequence + 1);
        //     } else {
        //       this.sequence.push(question.questions.sequence + 1);
        //     }
        //   }
        // } else {
        //   if (this.sequence && this.sequence.indexOf(question.questions.sequence + 1) >= 0) {
        //     this.sequence.splice(this.sequence.indexOf(question.questions.sequence + 1), 1);
        //   }
        // }
    };
    QuestionsComponent.prototype.setClass = function (classSelected) {
        var _this = this;
        if (classSelected.value === "class2") {
            if (confirm("Do you want to add a Fire Hose test ?")) {
                this.showHose = true;
            }
            else {
                this.showHose = false;
                this.inspectionService.setHoseCount(0);
                this.inspectionService.setHoseRows(null);
                Object.keys(this.qtd).forEach(function (key) {
                    if (key && key.indexOf("hose") > 0) {
                        _this.qtd[key] = null;
                    }
                });
            }
        }
        else {
            this.showHose = false;
            this.inspectionService.setHoseCount(0);
            this.inspectionService.setHoseRows(null);
            Object.keys(this.qtd).forEach(function (key) {
                if (key && key.indexOf("hose") > 0) {
                    _this.qtd[key] = null;
                }
            });
        }
    };
    QuestionsComponent.prototype.findQuestionId = function (description) {
        if (this.questions) {
            if (this.questions.filter(function (x) { return x.questions["question"] === description; }) &&
                this.questions.filter(function (x) { return x.questions["question"] === description; })[0]) {
                return this.questions.filter(function (x) { return x.questions["question"] === description; })[0].questions.questionId;
            }
        }
        return 0;
    };
    QuestionsComponent.prototype.uploadFileSimplified = function (event, files) {
        var _this = this;
        this.imagePlacement = true;
        var image = files.item(0);
        this.fileNameSimplified = image.name;
        if (image.size < 5 * 1024 * 1024) {
            if (image.type.toLowerCase() === "image/gif" ||
                image.type.toString() === "image/jpeg" ||
                image.type.toString() === "application/pdf") {
                this.imageUploadErrorSimplified = "";
                var reader_8 = new FileReader();
                reader_8.readAsDataURL(image);
                reader_8.onload = function () {
                    _this.imageSimplified = reader_8.result.toString();
                    event.value = "";
                };
                reader_8.onerror = function (error) {
                    _this.loggerService.log("Error on Loading Image", error);
                    _this.imageUploadErrorSimplified = "Error while Loading File";
                };
            }
            else {
                this.imageUploadErrorSimplified = "Invalid File Type";
            }
        }
        else if (image.size > 5 * 1024 * 1024) {
            this.imageUploadErrorSimplified = "File Size is greater than 5MB";
        }
        else if (image.type.toLowerCase() !== "gif" ||
            image.type.toString() !== "jpg" ||
            image.type.toString() !== "application/pdf") {
            this.imageUploadErrorSimplified = "Invalid File Type";
        }
    };
    QuestionsComponent.prototype.displayCondition = function (questionId, options, questionType) {
        if (questionType !== "DASC") {
            var value = true;
            // tslint:disable-next-line: prefer-for-of
            for (var i = 0; i < options.length; i++) {
                if (this.qtd["" + questionId + "*o*" + ("" + options[i]["data"])]) {
                    value = false;
                    break;
                }
            }
            return value;
        }
        else {
            return false;
        }
    };
    QuestionsComponent.prototype.ngOnDestroy = function () {
        this.subscriptions.forEach(function (x) {
            x.unsubscribe();
        });
    };
    QuestionsComponent.prototype.ngAfterViewChecked = function () {
        var _this = this;
        this.replacementValue = this.qtd[this.findQuestionId("Install:")]
            ? this.qtd[this.findQuestionId("Install:")] === "Replacement"
                ? true
                : false
            : false;
        if (!this.authRemarks && this.inspectionDataforSecondPage) {
            this.authRemarks = this.inspectionDataforSecondPage.authorityRemarks;
        }
        if (window.location.href.indexOf("reviewInspection") > 0 ||
            window.location.href.indexOf("printReport") > 0 ||
            window.location.href.indexOf("configureInspectionReport") > 0) {
            if (window.location.href.indexOf("configureInspectionReport") > 0) {
                this.assetTypeid = this.inspectionService.getAssetTypeId();
            }
            if (this.inspectionDataforSecondPage) {
                this.sequence = this.inspectionDataforSecondPage.sequence;
                this.showHose = this.inspectionDataforSecondPage.showHose;
                this.showPRV = this.inspectionDataforSecondPage.showPRV;
                if (!this.attachment ||
                    (this.attachment && this.attachment.length === 0)) {
                    if (this.inspectionDataforSecondPage.inspectionAttachment) {
                        this.attachment =
                            this.inspectionDataforSecondPage.inspectionAttachment;
                    }
                    if (this.attachment && this.attachment.length > 0) {
                        this.attachment.forEach(function (x) {
                            if (x.imagePlacement === "Simple") {
                                _this.imagePlacement = true;
                                _this.imageSimplified = x.attachment;
                                _this.fileNameSimplified = x.imageName;
                            }
                            if (x.imagePlacement === "Main") {
                                _this.image = x.attachment;
                                _this.fileName = x.imageName;
                            }
                            if (x.imagePlacement !== "Main" &&
                                x.imagePlacement !== "Simple" &&
                                _this.assetTypeid !== "14") {
                                if (!_this.photo1) {
                                    _this.photo1 = x.attachment;
                                    _this.photo1Name = x.imageName;
                                    return;
                                }
                                if (!_this.photo2) {
                                    _this.photo2 = x.attachment;
                                    _this.photo2Name = x.imageName;
                                    return;
                                }
                                if (!_this.photo3) {
                                    _this.photo3 = x.attachment;
                                    _this.photo3Name = x.imageName;
                                    return;
                                }
                                if (!_this.photo4) {
                                    _this.photo4 = x.attachment;
                                    _this.photo4Name = x.imageName;
                                    return;
                                }
                            }
                        });
                    }
                }
                setTimeout(function () {
                    return (_this.showSimplified =
                        _this.inspectionDataforSecondPage.inspectionType ===
                            "SIMPLIFIED_REPORT"
                            ? true
                            : false);
                });
            }
        }
    };
    QuestionsComponent.prototype.getBase64Image = function (img) {
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        var dataURL = canvas.toDataURL("image/png");
        return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
    };
    QuestionsComponent.prototype.getDownstreamValue = function () {
        if (this.qtd[this.findQuestionId("Downstream Process:")] === "DCVA" &&
            !this.downstreamOthersValue) {
            this.downstreamTypeInit = "DCVA";
            this.downstreamOthersValue = false;
        }
        else if (this.qtd[this.findQuestionId("Downstream Process:")] === "RPBA" &&
            !this.downstreamOthersValue) {
            this.downstreamTypeInit = "RPBA";
            this.downstreamOthersValue = false;
        }
        else if (this.qtd[this.findQuestionId("Downstream Process:")] === "PVBA" &&
            !this.downstreamOthersValue) {
            this.downstreamTypeInit = "PVBA";
            this.downstreamOthersValue = false;
        }
        else if (this.downstreamOthersValue &&
            this.qtd[this.findQuestionId("Downstream Process:")] === "OTHERS") {
            this.downstreamTypeInit = "OTHER";
            this.downstreamOthersValue = true;
        }
        else if (this.qtd[this.findQuestionId("Others")] &&
            this.qtd[this.findQuestionId("Others")][0].trim() !== "") {
            this.downstreamTypeInit = "OTHER";
            this.downstreamOthersValue = true;
        }
        return this.downstreamTypeInit;
    };
    // tslint:disable-next-line: use-lifecycle-interface
    QuestionsComponent.prototype.ngOnChanges = function (changes) {
        if (window.location.href.indexOf("configureInspectionReport") > 0) {
            this.assetTypeid = this.inspectionService.getAssetTypeId();
            this.configureFireAndSmokeDamperAsset();
        }
        if (changes["isSimplified"] && changes["isSimplified"].currentValue) {
            this.showSimplified = changes["isSimplified"].currentValue;
        }
        if (this.questions) {
            var a = this.questions.filter(function (res) { return res.questions.questionType === "Additional"; });
            this.additionalQuestionExist = a.length > 0;
        }
        if (changes["questions"] && changes["questions"].currentValue) {
            this.questions = tslib_1.__spread(changes["questions"].currentValue);
        }
        if (this.reviewInspection
            && this.reviewInspectionReport != null
            && this.reviewInspectionReport != null
            && this.reviewInspectionReport.assetTypeId == '17'
            && this.reviewInspectionReport.questionModelVersion > 4) {
            this.isDampnerTableQuestion = true;
        }
    };
    QuestionsComponent.prototype.configureFireAndSmokeDamperAsset = function () {
        this.isDampnerTableQuestion = false;
        if (this.assetTypeid == "17" &&
            this.inspectionResult &&
            this.inspectionResult.questionModelVersion &&
            this.inspectionResult.questionModelVersion > 4) {
            this.isDampnerTableQuestion = true;
        }
        if (this.configureReport && this.questions.length > 0 && this.questions[0]['assetType'] === 'Fire and Smoke Damper') {
            this.isDampnerTableQuestion = true;
        }
    };
    return QuestionsComponent;
}());
export { QuestionsComponent };
export { ɵ0 };
