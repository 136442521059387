<table cellspacing="0" cellpadding="0" class="sp-top-table1" width="100%" style="margin-bottom: 2%;">
  <tbody>
    <tr>
      <td class="header-td" colspan="9">CLASS II STANDPIPE - FIRE HOSE TESTING
      </td>
    </tr>
    <tr>
      <td width="12%">Hose Location(floor/Stair)</td>
      <td width="12%">Size(inches)</td>
      <td width="12%">Length(ft)</td>
      <td width="12%">Evaluation</td>
      <td width="12%">Test Psi</td>
      <td width="15%">Comments</td>
      <td *ngIf="!reviewInspection"> &nbsp;</td>
    </tr>
    <tr *ngFor="let k of hose;let i = index" [attr.data-index]="i">
      <td width="12%">
        <input type="text" style="width:63px;" [(ngModel)]="qtd[findQuestionIdSecond('Hose Location(floor/Stair)')+ '~hose~' + i + '~']"
          name="qtd-{{findQuestionIdSecond('Hose Location(floor/Stair)')}}-{{i}}"
          id="qtd-{{findQuestionIdSecond('Hose Location(floor/Stair)')}}-{{i}}" [disabled]="reviewInspection">
      </td>
      <td width="12%">
        <input type="text" style="width:63px;" [(ngModel)]="qtd[findQuestionIdSecond('Size(inches)')+ '~hose~' + i + '~']"
         name="qtd-{{findQuestionIdSecond('Size(inches)')}}-{{i}}"
          id="qtd-{{findQuestionIdSecond('Size(inches)')}}-{{i}}" [disabled]="reviewInspection">
      </td>
      <td width="12%">
        <input type="text" style="width:63px;" [(ngModel)]="qtd[findQuestionIdSecond('Length(ft)')+ '~hose~' + i + '~']"  
          name="qtd-{{findQuestionIdSecond('Length(ft)')}}-{{i}}" id="qtd-{{findQuestionIdSecond('Length(ft)')}}-{{i}}"
          [disabled]="reviewInspection">
      </td>
      <td width="12%">
        <input type="text" style="width:63px;" [(ngModel)]="qtd[findQuestionIdSecond('Evaluation')+ '~hose~' + i + '~']"
          name="qtd-{{findQuestionIdSecond('Evaluation')}}-{{i}}" id="qtd-{{findQuestionIdSecond('Evaluation')}}-{{i}}"
          [disabled]="reviewInspection">
      </td>
      <td width="12%">
        <input type="text" style="width:63px;" [(ngModel)]="qtd[findQuestionIdSecond('Test Psi')+ '~hose~' + i + '~']" 
          name="qtd-{{findQuestionIdSecond('Test Psi')}}-{{i}}" id="qtd-{{findQuestionIdSecond('Test Psi')}}-{{i}}"
          [disabled]="reviewInspection">
      </td>
      <td width="25%">
        <input type="text" style="width:103px;" [(ngModel)]="qtd[findQuestionIdSecond('Comments')+ '~hose~' + i + '~']" 
          name="qtd-{{findQuestionIdSecond('Comments')}}-{{i}}" id="qtd-{{findQuestionIdSecond('Comments')}}-{{i}}"
          [disabled]="reviewInspection">
      </td>
      <td width="30%" *ngIf="!reviewInspection">
        <button type="button" class="cust-but" *ngIf="!reviewInspection && hose.length !== 1" (click)="deleteRow(k)">Delete</button>
        <button type="button" class="cust-but" *ngIf="!reviewInspection && i === hose.length-1" (click)="addRow();">Add</button>
      </td>
    </tr>
  </tbody>
</table>
