import * as tslib_1 from "tslib";
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@angular/material/dialog";
var InspectionService = /** @class */ (function () {
    // geoLocationKey = environment.geoLocationMapAPIKey;
    function InspectionService(http, dialog) {
        this.http = http;
        this.dialog = dialog;
        this.baseUrl = environment.baseUrl;
        this.errorInFireAndSmokeDamperInspection = new BehaviorSubject(false);
    }
    InspectionService.prototype.setErrorInFireAndDamper = function (isError) {
        this.errorInFireAndSmokeDamperInspection.next(isError);
    };
    InspectionService.prototype.getErrorInfireAndDamper = function () {
        return this.errorInFireAndSmokeDamperInspection.asObservable();
    };
    InspectionService.prototype.setPac = function (pac) {
        this.pac = pac;
    };
    InspectionService.prototype.getPac = function () {
        return this.pac;
    };
    InspectionService.prototype.setIsImageUploadEnabled = function (isImageUploadEnabled) {
        this.isImageUploadEnabled = isImageUploadEnabled;
    };
    InspectionService.prototype.getIsImageUploadEnabled = function () {
        return this.isImageUploadEnabled;
    };
    InspectionService.prototype.setIsSimplifiedReportEnabled = function (isSimplifiedReportEnabled) {
        this.isSimplifiedReportEnabled = isSimplifiedReportEnabled;
    };
    InspectionService.prototype.getIsSimplifiedReportEnabled = function () {
        return this.isSimplifiedReportEnabled;
    };
    InspectionService.prototype.setJurisId = function (jurisId) {
        this.jurisId = jurisId;
    };
    InspectionService.prototype.getJurisIds = function () {
        return this.jurisId;
    };
    InspectionService.prototype.getDeficiencyProperties = function (reportId, deficiency, limit, offset, searchString, orderType) {
        if (deficiency === void 0) { deficiency = 'no'; }
        if (limit === void 0) { limit = '10'; }
        if (offset === void 0) { offset = '0'; }
        if (searchString === void 0) { searchString = ''; }
        if (orderType === void 0) { orderType = ''; }
        var url = this.baseUrl + "/deficiency/properties";
        return this.http.get(url, {
            params: {
                deficiency: deficiency,
                reportNumber: reportId,
                limit: limit,
                offset: offset,
                searchString: searchString,
                orderType: orderType
            }
        });
    };
    InspectionService.prototype.getRFADeficiencyProperties = function (reportId, jurisId, deficiency, limit, offset, searchString, orderType) {
        if (deficiency === void 0) { deficiency = 'no'; }
        if (limit === void 0) { limit = '10'; }
        if (offset === void 0) { offset = '0'; }
        if (searchString === void 0) { searchString = ''; }
        if (orderType === void 0) { orderType = ''; }
        if (jurisId === -1) {
            jurisId = 0;
        }
        if (!jurisId) {
            jurisId = 0;
        }
        var url = this.baseUrl + "/deficiency/properties";
        return this.http.get(url, {
            params: {
                deficiency: deficiency,
                reportNumber: reportId,
                jurisId: jurisId,
                limit: limit,
                offset: offset,
                searchString: searchString,
                orderType: orderType
            }
        });
    };
    InspectionService.prototype.getAssetTypeId = function () {
        return this.assetTypeId;
    };
    InspectionService.prototype.setAssetTypeId = function (row) {
        this.assetTypeId = row;
    };
    InspectionService.prototype.getHoseCount = function () {
        return this.hoseCount;
    };
    InspectionService.prototype.setHoseCount = function (row) {
        this.hoseCount = row;
    };
    InspectionService.prototype.getHoseRows = function () {
        return this.hoseRows;
    };
    InspectionService.prototype.setHoseRows = function (rows) {
        this.hoseRows = rows;
    };
    InspectionService.prototype.sePVRRows = function (row) {
        this.pvrRows = row;
    };
    InspectionService.prototype.getPVRRows = function () {
        return this.pvrRows;
    };
    InspectionService.prototype.setStandPipeRows = function (row) {
        this.standPipeRows = row;
    };
    InspectionService.prototype.getStandPipeRows = function () {
        return this.standPipeRows;
    };
    InspectionService.prototype.getPropertyDetailsById = function (propId) {
        var url = this.baseUrl + ("/propertyDetails/" + propId);
        return this.http.get(url);
    };
    InspectionService.prototype.getDateInfo = function (inspectionID) {
        var url = this.baseUrl + ("/inspectionResult/" + inspectionID);
        return this.http.get(url);
    };
    InspectionService.prototype.getCertifiedInspector = function (jurisdictionId, propertyAssetId) {
        var url = this.baseUrl + ("/inspector/certifiedInspector/" + jurisdictionId);
        return this.http.get(url, {
            params: {
                propertyAssetId: propertyAssetId
            }
        });
    };
    InspectionService.prototype.getInspectorList = function (jurisdictionId, propertyAssetId) {
        var url = this.baseUrl + ("/inspector/" + jurisdictionId);
        return this.http.get(url, {
            params: {
                propertyAssetId: propertyAssetId
            }
        });
    };
    InspectionService.prototype.getManagerDetails = function (propId) {
        var url = this.baseUrl + ("/property/manager/" + propId);
        return this.http.get(url);
    };
    InspectionService.prototype.getUserDetails = function () {
        var url = this.baseUrl + "/userDetails";
        return this.http.get(url);
    };
    InspectionService.prototype.getInspectionCompanyDetails = function (propId) {
        var url = this.baseUrl + ("/inspectionCompany/" + propId);
        return this.http.get(url);
    };
    InspectionService.prototype.getInspectorLincense = function (inspectorId) {
        var url = this.baseUrl + ("/inspector/license/" + inspectorId);
        return this.http.get(url);
    };
    InspectionService.prototype.getQuestions = function (assetTypeId, jurisdictionId, propertyAssetId) {
        var url = this.baseUrl + ("/question/" + assetTypeId + "/" + jurisdictionId);
        if (propertyAssetId) {
            return this.http.get(url, {
                params: {
                    propertyAssetId: propertyAssetId
                }
            });
        }
        else {
            return this.http.get(url);
        }
    };
    InspectionService.prototype.getInspectorProperties = function (name, address, offset, limit, isPAC) {
        var url = this.baseUrl + "/inspection/inspector/propertylist";
        return this.http.get(url, {
            params: {
                name: name,
                address: address,
                offset: offset,
                limit: limit,
                isPAC: isPAC
            }
        });
    };
    InspectionService.prototype.getAuthorityProperties = function (name, address, offset, limit) {
        var url = this.baseUrl + "/inspection/authority/propertylist";
        return this.http.get(url, {
            params: {
                name: name,
                address: address,
                offset: offset,
                limit: limit
            }
        });
    };
    InspectionService.prototype.getSiteAdminProperties = function (name, address, offset, limit) {
        var url = this.baseUrl + "/inspection/siteAdmin/propertylist";
        return this.http.get(url, {
            params: {
                name: name,
                address: address,
                offset: offset,
                limit: limit
            }
        });
    };
    InspectionService.prototype.getRFAProperties = function (name, address, jurisId, offset, limit) {
        if (!jurisId) {
            jurisId = 0;
        }
        var url = this.baseUrl + ("/inspection/rfa/propertylist/" + jurisId);
        return this.http.get(url, {
            params: {
                name: name,
                address: address,
                offset: offset,
                limit: limit
            }
        });
    };
    InspectionService.prototype.getAssetInfo = function (assetTypeId) {
        var url = this.baseUrl + ("/propertyAsset/" + assetTypeId);
        return this.http.get(url);
    };
    InspectionService.prototype.getAssetHoodType = function (assetTypeId) {
        var url = this.baseUrl + ("/propertyAsset/prepopulate/" + assetTypeId);
        return this.http.get(url);
    };
    InspectionService.prototype.isInspectionPresent = function (propAssetId) {
        var url = this.baseUrl + ("/isInspectionPresent/" + propAssetId);
        return this.http.get(url);
    };
    InspectionService.prototype.postInspectionResult = function (inspectionResult, propertyAssetId) {
        var url = this.baseUrl + ("/inspection/" + propertyAssetId);
        return this.http.post(url, inspectionResult);
    };
    InspectionService.prototype.putInspectionResult = function (inspectionResult, propertyAssetId) {
        var url = this.baseUrl + ("/inspection/" + propertyAssetId);
        return this.http.put(url, inspectionResult);
    };
    InspectionService.prototype.putInspectionDefResult = function (inspectionResult, correctedDate, correctedBy) {
        var url = this.baseUrl + "/inspection/deficiency";
        var requestBody = tslib_1.__assign({}, inspectionResult, { correctedBy: correctedBy, correctedDate: correctedDate });
        return this.http.put(url, requestBody);
    };
    InspectionService.prototype.getPreviousReports = function (sysId) {
        var url = this.baseUrl + ("/inspection/property/" + sysId + "/previousreports");
        return this.http.get(url);
    };
    InspectionService.prototype.postAnswer = function (assetTypeId, body) {
        var url = this.baseUrl + ("/answer/" + assetTypeId);
        return this.http.post(url, body);
    };
    InspectionService.prototype.sendConsolidatedInspectionResultAndAnswer = function (propertyAssetId, body) {
        var url = this.baseUrl + ("/inspection/answer/" + propertyAssetId);
        return this.http.post(url, body);
    };
    InspectionService.prototype.getQuestionsByReportId = function (inspectionReportId, jurisdictionId) {
        var url = this.baseUrl + ("/question/answer/" + inspectionReportId + "/" + jurisdictionId);
        return this.http.get(url);
    };
    InspectionService.prototype.getInspectionReportIdbyAssetId = function (assetId) {
        var url = this.baseUrl + ("/inspection/propertyAsset/" + assetId);
        return this.http.get(url);
    };
    InspectionService.prototype.getInspectionReportDetails = function (reportID) {
        var url = this.baseUrl + ("/inspection/" + reportID);
        return this.http.get(url);
    };
    InspectionService.prototype.getInspectorDetails = function (inspectorID) {
        var url = this.baseUrl + ("/inspector/details/" + inspectorID);
        return this.http.get(url);
    };
    InspectionService.prototype.submitInspections = function (ids) {
        var url = this.baseUrl + ("/submitInspection/" + ids);
        return this.http.post(url, null);
    };
    InspectionService.prototype.getPriceOfInspections = function (ids) {
        var url = this.baseUrl + ("/submitInspection/" + ids);
        return this.http.get(url);
    };
    InspectionService.prototype.makePayment = function (ids, body) {
        var url = this.baseUrl + ("/price/" + ids);
        return this.http.put(url, null);
    };
    InspectionService.prototype.getReviewInspectionProperties = function () {
        var url = this.baseUrl + "/inspection/reviewinspection/propertylist";
        return this.http.get(url);
    };
    InspectionService.prototype.getRFAReviewInspectionProperties = function (jurisId) {
        if (!jurisId) {
            jurisId = 0;
        }
        var url = this.baseUrl + ("/inspection/RFA/reviewinspection/propertylist/" + jurisId);
        return this.http.get(url);
    };
    InspectionService.prototype.putReviewInspection = function (detail, resultId) {
        var url = this.baseUrl + ("/inspection/inspectionresult/" + resultId + "/reviewinspection");
        return this.http.put(url, detail);
    };
    InspectionService.prototype.deleteInspection = function (propAssetId) {
        var url = this.baseUrl + ("/delete/inspection/" + propAssetId);
        return this.http.delete(url);
    };
    InspectionService.prototype.deleteInspectionPrevious = function (propAssetId, inspectionResultId, deletionComments) {
        var url = this.baseUrl + ("/delete/inspection/" + propAssetId);
        return this.http.delete(url, { params: { deletionComments: deletionComments, inspectionResultId: inspectionResultId } });
    };
    InspectionService.prototype.getReviewedData = function (inspectionResultId) {
        var url = this.baseUrl + ("/inspection/inspectionresult/" + inspectionResultId + "/reviewinspection");
        return this.http.get(url);
    };
    InspectionService.prototype.getPropertyByPAC = function (pac, userRole) {
        var url = '';
        switch (userRole) {
            case 'SiteAdmin':
                url = this.baseUrl + ("/inspection/siteAdmin/pac/" + pac);
                break;
            case 'Authority':
                url = this.baseUrl + ("/inspection/authority/pac/" + pac);
                break;
            case 'Inspector':
                url = this.baseUrl + ("/inspection/inspector/pac/" + pac);
                break;
        }
        return this.http.get(url);
    };
    InspectionService.prototype.getQuestionVersion = function (assetTypeID, jurisdictionId) {
        var url = this.baseUrl + ("/question/version/" + assetTypeID + "/" + jurisdictionId);
        return this.http.get(url);
    };
    InspectionService.prototype.getAuthoritySettings = function (assetTypeID, jurisdictionId) {
        var url = this.baseUrl + ("/authority_mapped_attributes/" + jurisdictionId + "/" + assetTypeID);
        return this.http.get(url);
    };
    InspectionService.prototype.postCustomQuestions = function (customQuestions) {
        var url = this.baseUrl + "/question/jurisdiction";
        return this.http.post(url, customQuestions);
    };
    InspectionService.prototype.postConfigureSettings = function (settings) {
        var url = this.baseUrl + "/authority_mapped_attributes";
        return this.http.post(url, settings);
    };
    InspectionService.prototype.getAssetName = function (assetTypeID) {
        var url = this.baseUrl + ("/assettype/" + assetTypeID);
        return this.http.get(url);
    };
    InspectionService.prototype.getInspectorWaitingPaymentPreference = function () {
        var url = this.baseUrl + "/inspector/preferences/waitingPayment";
        return this.http.get(url);
    };
    InspectionService.prototype.updateAssetsToWaitingPayment = function (ids) {
        var url = this.baseUrl + ("/inspection/submitInspepction/waitingPayment/" + ids);
        return this.http.put(url, {});
    };
    InspectionService.prototype.isAuthorizedToSubmit = function (ids) {
        var url = this.baseUrl + ("/inspection/submit/" + ids);
        return this.http.get(url);
    };
    InspectionService.prototype.getReportPDF = function (inspectionId) {
        var url = this.baseUrl + ("/report_thymeleaf/report/inspection/" + inspectionId);
        return this.http.get(url);
    };
    InspectionService.prototype.getPaymentDetailsById = function (ids) {
        var url = this.baseUrl + ("/price/" + ids);
        return this.http.get(url);
    };
    InspectionService.prototype.checkIfNotDeleted = function (id) {
        var url = this.baseUrl + ("/inspection/assetStatus/" + id);
        return this.http.get(url);
    };
    InspectionService.prototype.printBlankReport = function (id) {
        var url = this.baseUrl + ("/report_thymeleaf/report/" + id);
        return this.http.get(url);
    };
    InspectionService.prototype.exportDeficeincyProperties = function (searchString, reportNumber, jurisId) {
        if (jurisId === -1) {
            jurisId = 0;
        }
        if (!jurisId) {
            jurisId = 0;
        }
        var url = this.baseUrl + "/export/deficiency/properties";
        return this.http.get(url, {
            params: {
                searchString: searchString,
                reportNumber: reportNumber,
                jurisId: jurisId
            }
        });
    };
    InspectionService.prototype.getAllowedSystems = function (jurisdictionId) {
        var url = this.baseUrl + ("/authority/selected/system/" + jurisdictionId);
        return this.http.get(url);
    };
    InspectionService.prototype.updatePreviousDefieciencyStatus = function (data) {
        var url = this.baseUrl + "/inspection/prevdef/update/";
        return this.http.put(url, data);
    };
    InspectionService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function InspectionService_Factory() { return new InspectionService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.MatDialog)); }, token: InspectionService, providedIn: "root" });
    return InspectionService;
}());
export { InspectionService };
