import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import {
  InspectionPropertyInfo, InspectionProperty,
  InspectionActions, ReviewInspectionProperty, InspectionPropertyAPIResponse
} from '../../models/inspection/inspection.model';


@Injectable({
  providedIn: 'root'
})
export class InspectionService {

  baseUrl = environment.baseUrl;
  standPipeRows: any;
  pvrRows: any;
  hoseCount: any;
  hoseRows: any;
  assetTypeId: number;
  private errorInFireAndSmokeDamperInspection: BehaviorSubject<boolean>;
  public paymentReturnUrl: string;
  public pac: any;
  public isImageUploadEnabled: any;
  public isSimplifiedReportEnabled: any;
  public jurisId: any;
  // geoLocationKey = environment.geoLocationMapAPIKey;

  constructor(private http: HttpClient, public dialog: MatDialog) {
    this.errorInFireAndSmokeDamperInspection = new BehaviorSubject<boolean>(false);
  }

  setErrorInFireAndDamper(isError: boolean){
    this.errorInFireAndSmokeDamperInspection.next(isError)
  }

  getErrorInfireAndDamper(){
    return this.errorInFireAndSmokeDamperInspection.asObservable()
  }

  setPac(pac) {
    this.pac = pac;
  }

  getPac() {
    return this.pac;
  }

  setIsImageUploadEnabled(isImageUploadEnabled) {
    this.isImageUploadEnabled = isImageUploadEnabled;
  }

  getIsImageUploadEnabled() {
    return this.isImageUploadEnabled;
  }

  setIsSimplifiedReportEnabled(isSimplifiedReportEnabled) {
    this.isSimplifiedReportEnabled = isSimplifiedReportEnabled;
  }

  getIsSimplifiedReportEnabled() {
    return this.isSimplifiedReportEnabled;
  }

  setJurisId(jurisId) {
    this.jurisId = jurisId;
  }

  getJurisIds() {
    return this.jurisId;
  }


  public getDeficiencyProperties(reportId, deficiency = 'no', limit = '10', offset = '0', searchString = '', orderType = '' ) {
    const url = this.baseUrl + `/deficiency/properties`;
    return this.http.get<any>(url, {
      params: {
        deficiency: deficiency,
        reportNumber: reportId,
        limit: limit,
        offset: offset,
        searchString: searchString,
        orderType
      }
    });
  }

  public getRFADeficiencyProperties(reportId, jurisId, deficiency = 'no', limit = '10', offset = '0', searchString = '', orderType = '') {
    if (jurisId === -1) {
      jurisId = 0;
    }
    if (!jurisId) {
      jurisId = 0;
    }
    const url = this.baseUrl + `/deficiency/properties`;
    return this.http.get<any>(url, {
      params: {
        deficiency: deficiency,
        reportNumber: reportId,
        jurisId: jurisId,
        limit: limit,
        offset: offset,
        searchString: searchString,
        orderType
      }
    });
  }

  getAssetTypeId() {
    return this.assetTypeId;
  }
  setAssetTypeId(row) {
    this.assetTypeId = row;
  }

  getHoseCount() {
    return this.hoseCount;
  }
  setHoseCount(row) {
    this.hoseCount = row;
  }

  getHoseRows() {
    return this.hoseRows;
  }

  setHoseRows(rows) {
    this.hoseRows = rows;
  }
  sePVRRows(row) {
    this.pvrRows = row;
  }

  getPVRRows() {
    return this.pvrRows;
  }
  setStandPipeRows(row) {
    this.standPipeRows = row;
  }

  getStandPipeRows() {
    return this.standPipeRows;
  }
  getPropertyDetailsById(propId): Observable<InspectionPropertyInfo> {
    const url = this.baseUrl + `/propertyDetails/${propId}`;
    return this.http.get<InspectionPropertyInfo>(url);
  }

  getDateInfo(inspectionID) {
    const url = this.baseUrl + `/inspectionResult/${inspectionID}`;
    return this.http.get<any>(url);
  }

  getCertifiedInspector(jurisdictionId,propertyAssetId) {
    const url = this.baseUrl + `/inspector/certifiedInspector/${jurisdictionId}`;
    return this.http.get<any>(url, {
      params: {
        propertyAssetId:propertyAssetId
      }
    });
  }
    getInspectorList(jurisdictionId,propertyAssetId) {
    const url = this.baseUrl + `/inspector/${jurisdictionId}`;
    return this.http.get<any>(url, {
      params: {
        propertyAssetId:propertyAssetId
      }
    });
  }

  getManagerDetails(propId: number) {
    const url = this.baseUrl + `/property/manager/${propId}`;
    return this.http.get<any>(url);
  }

  getUserDetails() {
    const url = this.baseUrl + `/userDetails`;
    return this.http.get<any>(url);
  }

  getInspectionCompanyDetails(propId: number) {
    const url = this.baseUrl + `/inspectionCompany/${propId}`;
    return this.http.get<any>(url);
  }

  getInspectorLincense(inspectorId: string) {
    const url = this.baseUrl + `/inspector/license/${inspectorId}`;
    return this.http.get<any>(url);
  }

  getQuestions(assetTypeId: number, jurisdictionId: number, propertyAssetId?) {
    const url = this.baseUrl + `/question/${assetTypeId}/${jurisdictionId}`;
    if (propertyAssetId) {
      return this.http.get<any>(url,{
        params: {
          propertyAssetId:propertyAssetId
        }
      });
    } else {
      return this.http.get<any>(url);
    }
  }

  getInspectorProperties(name, address, offset, limit, isPAC): Observable<InspectionPropertyAPIResponse> {
    const url = this.baseUrl + `/inspection/inspector/propertylist`;
    return this.http.get<InspectionPropertyAPIResponse>(url, {
      params: {
        name: name,
        address: address,
        offset: offset,
        limit: limit,
        isPAC: isPAC
      }
    });
  }

  getAuthorityProperties(name, address, offset, limit): Observable<InspectionPropertyAPIResponse> {
    const url = this.baseUrl + `/inspection/authority/propertylist`;
    return this.http.get<InspectionPropertyAPIResponse>(url, {
      params: {
        name: name,
        address: address,
        offset: offset,
        limit: limit
      }
    });
  }

  getSiteAdminProperties(name, address, offset, limit): Observable<InspectionPropertyAPIResponse> {
    const url = this.baseUrl + `/inspection/siteAdmin/propertylist`;
    return this.http.get<InspectionPropertyAPIResponse>(url, {
      params: {
        name: name,
        address: address,
        offset: offset,
        limit: limit
      }
    });
  }

  getRFAProperties(name, address, jurisId, offset, limit): Observable<InspectionPropertyAPIResponse> {
    if (!jurisId) {
      jurisId = 0;
    }
    const url = this.baseUrl + `/inspection/rfa/propertylist/${jurisId}`;
    return this.http.get<InspectionPropertyAPIResponse>(url, {
      params: {
        name: name,
        address: address,
        offset: offset,
        limit: limit
      }
    });
  }

  getAssetInfo(assetTypeId: number) {
    const url = this.baseUrl + `/propertyAsset/${assetTypeId}`;
    return this.http.get<any>(url);
  }

  getAssetHoodType(assetTypeId: number) {
    const url = this.baseUrl + `/propertyAsset/prepopulate/${assetTypeId}`;
    return this.http.get<any>(url);
  }

  isInspectionPresent(propAssetId) {
    const url = this.baseUrl + `/isInspectionPresent/${propAssetId}`;
    return this.http.get<any>(url);
  }

  postInspectionResult(inspectionResult, propertyAssetId) {
    const url = this.baseUrl + `/inspection/${propertyAssetId}`;
    return this.http.post<any>(url, inspectionResult);
  }
  putInspectionResult(inspectionResult, propertyAssetId) {
    const url = this.baseUrl + `/inspection/${propertyAssetId}`;
    return this.http.put<any>(url, inspectionResult);
  }
  putInspectionDefResult(inspectionResult, correctedDate, correctedBy) {
    const url = this.baseUrl + `/inspection/deficiency`;
    const requestBody = {
      ...inspectionResult,
      correctedBy: correctedBy,
      correctedDate: correctedDate,
    };
  
    return this.http.put<any>(url, requestBody);
  }
  getPreviousReports(sysId) {
    const url = this.baseUrl + `/inspection/property/${sysId}/previousreports`;
    return this.http.get<InspectionActions>(url);
  }
  public postAnswer(assetTypeId, body) {
    const url = this.baseUrl + `/answer/${assetTypeId}`;
    return this.http.post<any>(url, body);
  }

  public sendConsolidatedInspectionResultAndAnswer(propertyAssetId, body){
    const url = this.baseUrl + `/inspection/answer/${propertyAssetId}`;
    return this.http.post<any>(url, body);
  }

  getQuestionsByReportId(inspectionReportId, jurisdictionId: number) {
    const url = this.baseUrl + `/question/answer/${inspectionReportId}/${jurisdictionId}`;
    return this.http.get<any>(url);
  }
  getInspectionReportIdbyAssetId(assetId) {
    const url = this.baseUrl + `/inspection/propertyAsset/${assetId}`;
    return this.http.get<any>(url);
  }
  getInspectionReportDetails(reportID) {
    const url = this.baseUrl + `/inspection/${reportID}`;
    return this.http.get<any>(url);
  }
  getInspectorDetails(inspectorID) {
    const url = this.baseUrl + `/inspector/details/${inspectorID}`;
    return this.http.get<any>(url);
  }

  submitInspections(ids) {
    const url = this.baseUrl + `/submitInspection/${ids}`;
    return this.http.post<null>(url, null);
  }

  getPriceOfInspections(ids) {
    const url = this.baseUrl + `/submitInspection/${ids}`;
    return this.http.get<null>(url);
  }

  makePayment(ids, body) {
    const url = this.baseUrl + `/price/${ids}`;
    return this.http.put<null>(url,null);
  }

  getReviewInspectionProperties() {
    const url = this.baseUrl + `/inspection/reviewinspection/propertylist`;
    return this.http.get<ReviewInspectionProperty[]>(url);
  }

  getRFAReviewInspectionProperties(jurisId) {
    if (!jurisId) {
      jurisId = 0;
    }
    const url = this.baseUrl + `/inspection/RFA/reviewinspection/propertylist/${jurisId}`;
    return this.http.get<ReviewInspectionProperty[]>(url);
  }

  public putReviewInspection(detail: any, resultId) {
    const url = this.baseUrl + `/inspection/inspectionresult/${resultId}/reviewinspection`;
    return this.http.put<any>(url, detail);
  }

  deleteInspection(propAssetId) {
    const url = this.baseUrl + `/delete/inspection/${propAssetId}`;
    return this.http.delete<any>(url);
  }


  deleteInspectionPrevious(propAssetId, inspectionResultId, deletionComments) {
    const url = this.baseUrl + `/delete/inspection/${propAssetId}`;
    return this.http.delete(url, { params: { deletionComments, inspectionResultId } });
  }

  public getReviewedData(inspectionResultId) {
    const url = this.baseUrl + `/inspection/inspectionresult/${inspectionResultId}/reviewinspection`;
    return this.http.get<any>(url);
  }

  getPropertyByPAC(pac: string, userRole: string) {
    let url = '';
    switch (userRole) {
      case 'SiteAdmin':
        url = this.baseUrl + `/inspection/siteAdmin/pac/${pac}`;
        break;
      case 'Authority':
        url = this.baseUrl + `/inspection/authority/pac/${pac}`;
        break;
      case 'Inspector':
        url = this.baseUrl + `/inspection/inspector/pac/${pac}`;
        break;
    }

    return this.http.get<InspectionProperty>(url);
  }

  getQuestionVersion(assetTypeID, jurisdictionId: number) {
    const url = this.baseUrl + `/question/version/${assetTypeID}/${jurisdictionId}`;
    return this.http.get<any>(url);
  }

  getAuthoritySettings(assetTypeID, jurisdictionId: number) {
    const url = this.baseUrl + `/authority_mapped_attributes/${jurisdictionId}/${assetTypeID}`;
    return this.http.get<any>(url);
  }

  postCustomQuestions(customQuestions) {
    const url = this.baseUrl + `/question/jurisdiction`;
    return this.http.post<any>(url, customQuestions);
  }

  postConfigureSettings(settings) {
    const url = this.baseUrl + `/authority_mapped_attributes`;
    return this.http.post<any>(url, settings);
  }

  getAssetName(assetTypeID) {
    const url = this.baseUrl + `/assettype/${assetTypeID}`;
    return this.http.get<any>(url);
  }

  getInspectorWaitingPaymentPreference() {
    const url = this.baseUrl + `/inspector/preferences/waitingPayment`;
    return this.http.get<any>(url);
  }

  updateAssetsToWaitingPayment(ids) {
    const url = this.baseUrl + `/inspection/submitInspepction/waitingPayment/${ids}`;
    return this.http.put<any>(url, {});
  }

  isAuthorizedToSubmit(ids: string){
    const url = this.baseUrl + `/inspection/submit/${ids}`
    return this.http.get<any>(url);
  }


  getReportPDF(inspectionId: number) {
    const url = this.baseUrl + `/report_thymeleaf/report/inspection/${inspectionId}`;
    return this.http.get<any>(url);
  }

  getPaymentDetailsById(ids) {
    const url = this.baseUrl + `/price/${ids}`;
    return this.http.get<any>(url);
  }

  checkIfNotDeleted(id){
    const url = this.baseUrl + `/inspection/assetStatus/${id}`;
    return this.http.get<any>(url);
  }

  printBlankReport(id: number) {
    const url = this.baseUrl + `/report_thymeleaf/report/${id}`;
    return this.http.get<any>(url);
  }

  exportDeficeincyProperties(searchString: string, reportNumber: string, jurisId) {
    if (jurisId === -1) {
      jurisId = 0;
    }
    if (!jurisId) {
      jurisId = 0;
    }
    const url = this.baseUrl + `/export/deficiency/properties`;
    return this.http.get<any>(url, {
      params: {
        searchString: searchString,
        reportNumber: reportNumber,
        jurisId : jurisId
      }
    });
  }

  getAllowedSystems(jurisdictionId: number) {
    const url = this.baseUrl + `/authority/selected/system/${jurisdictionId}`;
    return this.http.get<any>(url);
  }

  updatePreviousDefieciencyStatus(data) {
    const url = this.baseUrl + `/inspection/prevdef/update/`;
    return this.http.put<any>(url,data);
  }


}
