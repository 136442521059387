import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
var CommunicationDevicesComponent = /** @class */ (function () {
    function CommunicationDevicesComponent() {
    }
    CommunicationDevicesComponent.prototype.ngOnInit = function () {
        this.init();
    };
    CommunicationDevicesComponent.prototype.init = function () {
        this.communicationTable = {
            phoneSets: {
                'unitsInBuilding': this.findAnswerbyQuestion('Phone Sets', 'unitsInBuilding') || '',
                'unitsTested': this.findAnswerbyQuestion('Phone Sets', 'unitsTested') || '',
                'resultsAcceptable': this.findAnswerbyQuestion('Phone Sets', 'resultsAcceptable') || 'N/A'
            },
            phoneJacks: {
                'unitsInBuilding': this.findAnswerbyQuestion('Phone Jacks', 'unitsInBuilding') || '',
                'unitsTested': this.findAnswerbyQuestion('Phone Jacks', 'unitsTested') || '',
                'resultsAcceptable': this.findAnswerbyQuestion('Phone Jacks', 'resultsAcceptable') || 'N/A'
            },
            callInSignal: {
                'unitsInBuilding': this.findAnswerbyQuestion('Call-in Signal', 'unitsInBuilding') || '',
                'unitsTested': this.findAnswerbyQuestion('Call-in Signal', 'unitsTested') || '',
                'resultsAcceptable': this.findAnswerbyQuestion('Call-in Signal', 'resultsAcceptable') || 'N/A'
            }
        };
    };
    CommunicationDevicesComponent.prototype.ngOnChanges = function (changes) {
        if (changes['questions'] && changes['questions'].currentValue) {
            this.questions = tslib_1.__spread(changes['questions'].currentValue);
            this.init();
        }
    };
    CommunicationDevicesComponent.prototype.findAnswerbyQuestion = function (question, description) {
        var myQuestion = this.questions.filter(function (x) { return x.questions.question === question; });
        if (myQuestion.length > 0) {
            if (myQuestion[0].questions &&
                myQuestion[0].questions.tableAnswers &&
                myQuestion[0].questions.tableAnswers.length > 0 &&
                myQuestion[0].questions.tableAnswers[0][description] && this.qtd[this.findQuestionId(question)][description] == null) {
                this.updateModel(question, description, myQuestion[0].questions.tableAnswers[0][description]);
                return myQuestion[0].questions.tableAnswers[0][description];
            }
            else {
                if (description === 'resultsAcceptable') {
                    this.updateModel(question, description, 'N/A');
                    return 'N/A';
                }
                else {
                    return '';
                }
            }
        }
        return;
    };
    CommunicationDevicesComponent.prototype.findQuestionId = function (description) {
        var myQuestion = this.questions.filter(function (x) { return x.questions.question === description; });
        if (myQuestion.length > 0) {
            return myQuestion[0].questions.questionId;
        }
        return 0;
    };
    CommunicationDevicesComponent.prototype.updateModel = function (question, column, value) {
        var id = this.findQuestionId(question);
        if (!this.qtd[id] || typeof this.qtd[id][0] === 'string') {
            this.qtd[id] = {};
        }
        if (column === 'unitsInBuilding') {
            this.qtd[id][column] = value;
        }
        else if (column === 'unitsTested') {
            this.qtd[id][column] = value;
        }
        else if (column === 'resultsAcceptable') {
            if (!this.qtd[id][column]) {
                this.qtd[id][column] = value;
            }
        }
    };
    return CommunicationDevicesComponent;
}());
export { CommunicationDevicesComponent };
