import * as i0 from "@angular/core";
var QuestionReportService = /** @class */ (function () {
    function QuestionReportService() {
        this.reportData = {};
    }
    QuestionReportService.prototype.saveReportData = function (data) {
        this.reportData = data;
    };
    QuestionReportService.prototype.getReportData = function () {
        return this.reportData;
    };
    QuestionReportService.prototype.clearReportData = function () {
        this.reportData = {}; // Clears all stored data
    };
    QuestionReportService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function QuestionReportService_Factory() { return new QuestionReportService(); }, token: QuestionReportService, providedIn: "root" });
    return QuestionReportService;
}());
export { QuestionReportService };
