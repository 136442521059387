import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
var OtherDevicesComponent = /** @class */ (function () {
    function OtherDevicesComponent() {
    }
    OtherDevicesComponent.prototype.ngOnInit = function () {
        this.init();
    };
    OtherDevicesComponent.prototype.init = function () {
        this.systemTable = {
            bells: {
                'unitsInBuilding': this.findAnswerbyQuestion('Bells, Horns, Chimes', 'unitsInBuilding') || '',
                'unitsTested': this.findAnswerbyQuestion('Bells, Horns, Chimes', 'unitsTested') || '',
                'resultsAcceptable': this.findAnswerbyQuestion('Bells, Horns, Chimes', 'resultsAcceptable') || 'N/A'
            },
            hornStrobes: {
                'unitsInBuilding': this.findAnswerbyQuestion('Horn Strobes', 'unitsInBuilding') || '',
                'unitsTested': this.findAnswerbyQuestion('Horn Strobes', 'unitsTested') || '',
                'resultsAcceptable': this.findAnswerbyQuestion('Horn Strobes', 'resultsAcceptable') || 'N/A'
            },
            voiceSpeakers: {
                'unitsInBuilding': this.findAnswerbyQuestion('Voice Speakers (Voice Clarity)', 'unitsInBuilding') || '',
                'unitsTested': this.findAnswerbyQuestion('Voice Speakers (Voice Clarity)', 'unitsTested') || '',
                'resultsAcceptable': this.findAnswerbyQuestion('Voice Speakers (Voice Clarity)', 'resultsAcceptable') || 'N/A'
            },
            smokeDetectors: {
                'unitsInBuilding': this.findAnswerbyQuestion('Smoke Detectors', 'unitsInBuilding') || '',
                'unitsTested': this.findAnswerbyQuestion('Smoke Detectors', 'unitsTested') || '',
                'resultsAcceptable': this.findAnswerbyQuestion('Smoke Detectors', 'resultsAcceptable') || 'N/A'
            },
            heatDetectors: {
                'unitsInBuilding': this.findAnswerbyQuestion('Heat Detectors', 'unitsInBuilding') || '',
                'unitsTested': this.findAnswerbyQuestion('Heat Detectors', 'unitsTested') || '',
                'resultsAcceptable': this.findAnswerbyQuestion('Heat Detectors', 'resultsAcceptable') || 'N/A'
            },
            ductDetectors: {
                'unitsInBuilding': this.findAnswerbyQuestion('Duct Detectors', 'unitsInBuilding') || '',
                'unitsTested': this.findAnswerbyQuestion('Duct Detectors', 'unitsTested') || '',
                'resultsAcceptable': this.findAnswerbyQuestion('Duct Detectors', 'resultsAcceptable') || 'N/A'
            },
            sprinklerFlow: {
                'unitsInBuilding': this.findAnswerbyQuestion('Sprinkler Flow Switches', 'unitsInBuilding') || '',
                'unitsTested': this.findAnswerbyQuestion('Sprinkler Flow Switches', 'unitsTested') || '',
                'resultsAcceptable': this.findAnswerbyQuestion('Sprinkler Flow Switches', 'resultsAcceptable') || 'N/A'
            },
            sprinklerSupervisory: {
                'unitsInBuilding': this.findAnswerbyQuestion('Sprinkler Supervisory Switches', 'unitsInBuilding') || '',
                'unitsTested': this.findAnswerbyQuestion('Sprinkler Supervisory Switches', 'unitsTested') || '',
                'resultsAcceptable': this.findAnswerbyQuestion('Sprinkler Supervisory Switches', 'resultsAcceptable') || 'N/A'
            },
            visualAlarm: {
                'unitsInBuilding': this.findAnswerbyQuestion('Visual Alarm Devices', 'unitsInBuilding') || '',
                'unitsTested': this.findAnswerbyQuestion('Visual Alarm Devices', 'unitsTested') || '',
                'resultsAcceptable': this.findAnswerbyQuestion('Visual Alarm Devices', 'resultsAcceptable') || 'N/A'
            },
            manualPull: {
                'unitsInBuilding': this.findAnswerbyQuestion('Manual Pull Stations', 'unitsInBuilding') || '',
                'unitsTested': this.findAnswerbyQuestion('Manual Pull Stations', 'unitsTested') || '',
                'resultsAcceptable': this.findAnswerbyQuestion('Manual Pull Stations', 'resultsAcceptable') || 'N/A'
            },
            automaticDoorUnlocks: {
                'unitsInBuilding': this.findAnswerbyQuestion('Automatic Door Unlocks', 'unitsInBuilding') || '',
                'unitsTested': this.findAnswerbyQuestion('Automatic Door Unlocks', 'unitsTested') || '',
                'resultsAcceptable': this.findAnswerbyQuestion('Automatic Door Unlocks', 'resultsAcceptable') || 'N/A'
            },
            automaticDoorRelease: {
                'unitsInBuilding': this.findAnswerbyQuestion('Automatic Door Release', 'unitsInBuilding') || '',
                'unitsTested': this.findAnswerbyQuestion('Automatic Door Release', 'unitsTested') || '',
                'resultsAcceptable': this.findAnswerbyQuestion('Automatic Door Release', 'resultsAcceptable') || 'N/A'
            },
            annunciator: {
                'unitsInBuilding': this.findAnswerbyQuestion('Annunciator(s)', 'unitsInBuilding') || '',
                'unitsTested': this.findAnswerbyQuestion('Annunciator(s)', 'unitsTested') || '',
                'resultsAcceptable': this.findAnswerbyQuestion('Annunciator(s)', 'resultsAcceptable') || 'N/A'
            },
            beamDetectors: {
                'unitsInBuilding': this.findAnswerbyQuestion('Beam Detectors', 'unitsInBuilding') || '',
                'unitsTested': this.findAnswerbyQuestion('Beam Detectors', 'unitsTested') || '',
                'resultsAcceptable': this.findAnswerbyQuestion('Beam Detectors', 'resultsAcceptable') || 'N/A'
            },
            fireDampers: {
                'unitsInBuilding': this.findAnswerbyQuestion('Fire Dampers', 'unitsInBuilding') || '',
                'unitsTested': this.findAnswerbyQuestion('Fire Dampers', 'unitsTested') || '',
                'resultsAcceptable': this.findAnswerbyQuestion('Fire Dampers', 'resultsAcceptable') || 'N/A'
            }
        };
    };
    OtherDevicesComponent.prototype.ngOnChanges = function (changes) {
        if (changes['questions'] && changes['questions'].currentValue) {
            this.questions = tslib_1.__spread(changes['questions'].currentValue);
            this.init();
        }
    };
    OtherDevicesComponent.prototype.findAnswerbyQuestion = function (question, description) {
        var myQuestion = this.questions.filter(function (x) { return x.questions.question === question; });
        if (myQuestion.length > 0) {
            if (myQuestion[0].questions &&
                myQuestion[0].questions.tableAnswers &&
                myQuestion[0].questions.tableAnswers.length > 0 &&
                myQuestion[0].questions.tableAnswers[0][description] && this.qtd[this.findQuestionId(question)][description] == null) {
                this.updateModel(question, description, myQuestion[0].questions.tableAnswers[0][description]);
                return myQuestion[0].questions.tableAnswers[0][description];
            }
            else {
                if (description === 'resultsAcceptable') {
                    this.updateModel(question, description, 'N/A');
                    return 'N/A';
                }
                else {
                    return '';
                }
            }
        }
        return;
    };
    OtherDevicesComponent.prototype.findQuestionId = function (description) {
        var myQuestion = this.questions.filter(function (x) { return x.questions.question === description; });
        if (myQuestion.length > 0) {
            return myQuestion[0].questions.questionId;
        }
        return 0;
    };
    OtherDevicesComponent.prototype.updateModel = function (question, column, value) {
        var id = this.findQuestionId(question);
        if (!this.qtd[id] || typeof this.qtd[id][0] === 'string') {
            this.qtd[id] = {};
        }
        if (column === 'unitsInBuilding') {
            this.qtd[id][column] = value;
        }
        else if (column === 'unitsTested') {
            this.qtd[id][column] = value;
        }
        else if (column === 'resultsAcceptable') {
            if (!this.qtd[id][column]) {
                this.qtd[id][column] = value;
            }
        }
    };
    return OtherDevicesComponent;
}());
export { OtherDevicesComponent };
