import { Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, ViewChild } from "@angular/core";
import {
  FireAndSmokeDamper,
  HeadingQuestion,
} from "../../../core/models/inspection/inspection.model";
import { InspectionService } from "../../../core/services/inspection/inspection.service";
import { Subscription } from "rxjs";

@Component({
  selector: "app-fire-smoke-damper",
  templateUrl: "./fire-smoke-damper.component.html",
  styleUrls: ["./fire-smoke-damper.component.scss"],
})
export class FireSmokeDamperComponent implements OnInit, OnDestroy, OnChanges {
  @Input() questions: Array<HeadingQuestion>;
  @Input() qtd: Array<any>;
  @Input() reviewInspection: boolean;

  @ViewChild("fireSmokeDamperTable", {static: false}) FSDTable: ElementRef;
  @ViewChild("fireSmokeDamperBinderquestion", {static: false}) FSDBinderQuestion: ElementRef;

  constructor(private inspectionService: InspectionService) {}
  fireAndSmokeTable: FireAndSmokeDamper[] = [];
  accountTypeOptions: any = [];
  typeOption: any = [];
  binderQuestion: string;

  isError: boolean = false;
  showErrorMessage: boolean = false;
  isErrorInTable: boolean = false;

  //#region Private Variables

  private _subscriptions: Subscription[] = [];

  private damperQuestionId: number;
  private accountTypeId: number;
  private typeId: number;
  private locationId: number;
  private questionOneId: number;
  private questionTwoId: number;
  private questionThreeId: number;
  private commentId: number;
  private binderQuestionId: number;

  //#endregion

  ngOnInit() {
    if(!this.reviewInspection){
      this.setQuestionIds();
      this.setAccountTypeMasterData();
      this.setTypeMasterData();
            
      this.buildQuestionModel();
    }

    this.enableSubscriptions();
  }

  ngOnChanges(){
    if(this.reviewInspection){
      this.setQuestionIds();
      this.setAccountTypeMasterData();
      this.setTypeMasterData();
        
      this.buildQuestionModel();
    }
  }

  //#region Auxilary Functions

  private findQuestionId(question) {
    let ques = this.questions.filter(
      (o) => o.questions && o.questions.question == question
    );
    if (ques.length > 0 && ques[0] && ques[0].questions) {
      return ques[0].questions.questionId;
    }
    return null;
  }

  private setQuestionIds() {
    this.damperQuestionId = this.findQuestionId("Damper ID#:");
    this.accountTypeId = this.findQuestionId("Acc. Type:");
    this.typeId = this.findQuestionId("Type:");
    this.locationId = this.findQuestionId("Location:");
    this.questionOneId = this.findQuestionId("Test 1");
    this.questionTwoId = this.findQuestionId("Test 2");
    this.questionThreeId = this.findQuestionId("Test 3");
    this.commentId = this.findQuestionId("Comments:");
    this.binderQuestionId = this.findQuestionId("Binder left on-site?");
  }

  private setAccountTypeMasterData() {

    this.accountTypeOptions = []

    this.accountTypeOptions.push({
      id: "",
      label: "select",
      isSelected: true,
    });

    const accountTypeOptionQuestion = this.questions.filter(
      (o) => o.questions && o.questions.question === "Acc. Type:"
    );
    if (
      accountTypeOptionQuestion.length > 0 &&
      accountTypeOptionQuestion[0] &&
      accountTypeOptionQuestion[0].questions &&
      accountTypeOptionQuestion[0].questions.options
    ) {
      accountTypeOptionQuestion[0].questions.options.forEach((o) => {
        this.accountTypeOptions.push({
          id: o["data"],
          label: o["data"],
          isSelected: false,
        });
      });
    }
  }

  private setTypeMasterData() {
    this.typeOption = []

    this.typeOption.push({
      id: "",
      label: "select",
      isSelected: true,
    });

    const typeOptionQuestion = this.questions.filter(
      (o) => o.questions && o.questions.question === "Type:"
    );

    if (
      typeOptionQuestion.length > 0 &&
      typeOptionQuestion[0] &&
      typeOptionQuestion[0].questions &&
      typeOptionQuestion[0].questions.options
    ) {
      typeOptionQuestion[0].questions.options.forEach((o) => {
        this.typeOption.push({
          id: o["data"],
          label: o["data"],
          isSelected: false,
        });
      });
    }
  }

  private enableSubscriptions() {
    this._subscriptions.push(
      this.inspectionService.getErrorInfireAndDamper().subscribe((res) => {
        this.isError = res;
        this.showErrorMessage = res;

        let tableEmpty = this.fireAndSmokeTable.filter(
          (o) =>
            !(
              o.id &&
              o.accountType &&
              o.fireAndDamperType &&
              o.location &&
              o.testOne &&
              o.testTwo &&
              o.testThree
            )
        );
        if (tableEmpty.length > 0) {
          this.isErrorInTable = res;
        }

      })
    );
  }

  private bindWithQTDData() {
    this.fireAndSmokeTable.forEach((o, i) => {

      if(this.damperQuestionId)
      this.qtd[`${this.damperQuestionId}~firesmokedamper~${i}~`] = o.id;

      if(this.accountTypeId)
      this.qtd[`${this.accountTypeId}~firesmokedamper~${i}~`] =
        o.otherAccountType != "" ? o.otherAccountType : o.accountType;

      if(this.typeId)
      this.qtd[`${this.typeId}~firesmokedamper~${i}~`] =
        o.otherFireAndDamperType != ""
          ? o.otherFireAndDamperType
          : o.fireAndDamperType;

      if(this.locationId)
      this.qtd[`${this.locationId}~firesmokedamper~${i}~`] = o.location;

      if(this.questionOneId)
      this.qtd[`${this.questionOneId}~firesmokedamper~${i}~`] = o.testOne;

      if(this.questionTwoId)
      this.qtd[`${this.questionTwoId}~firesmokedamper~${i}~`] = o.testTwo;
      
      if(this.questionThreeId)
      this.qtd[`${this.questionThreeId}~firesmokedamper~${i}~`] = o.testThree;
      
      if(this.commentId)
      this.qtd[`${this.commentId}~firesmokedamper~${i}~`] = o.comments;
    });    
  }

  private buildQuestionModel() {

    //object empty check
    if(Object.keys(this.qtd).length == 0 && this.questions.length == 0)
    return;

    // set binder question
    if (
      this.qtd &&
      this.qtd[this.binderQuestionId] &&
      this.qtd[this.binderQuestionId][0]
    ) {
      this.binderQuestion = this.qtd[this.binderQuestionId].toString();
    }

    // Table Setup
    let totalTableRows = this.qtd[this.damperQuestionId]
      ? this.qtd[this.damperQuestionId][0].split("~")
      : [];
    if(totalTableRows.length==0){
    totalTableRows=this.getMatchingKeysById(this.damperQuestionId.toString());
    }

    if (totalTableRows.length > 0) {
      totalTableRows.forEach((element, index) => {
        let damperIdAns;
        let accountTypeAns ;
        let typeIdAns ;
        let locationAns  ;
        let q1Ans ;
        let q2Ans;
        let q3Ans ;
        let commentAns;
        if(this.qtd[this.damperQuestionId + "~firesmokedamper~" + index + "~"]!=null){
          damperIdAns = this.qtd[this.damperQuestionId + "~firesmokedamper~" + index + "~"]
         accountTypeAns = this.qtd[this.accountTypeId + "~firesmokedamper~" + index + "~"]
         typeIdAns = this.qtd[this.typeId + "~firesmokedamper~" + index + "~"]
        locationAns = this.qtd[this.locationId + "~firesmokedamper~" + index + "~"]
         q1Ans = this.qtd[this.questionOneId + "~firesmokedamper~" + index + "~"]
         q2Ans = this.qtd[this.questionTwoId + "~firesmokedamper~" + index + "~"]
         q3Ans = this.qtd[this.questionThreeId + "~firesmokedamper~" + index + "~"]
         commentAns = this.qtd[this.commentId + "~firesmokedamper~" + index + "~"]
        }else{

         damperIdAns = this.qtd[this.damperQuestionId][0].split("~")[index];
         accountTypeAns = this.qtd[this.accountTypeId][0].split("~")[index];
         typeIdAns = this.qtd[this.typeId][0].split("~")[index];
        locationAns = this.qtd[this.locationId][0].split("~")[index];
         q1Ans = this.qtd[this.questionOneId][0].split("~")[index];
         q2Ans = this.qtd[this.questionTwoId][0].split("~")[index];
         q3Ans = this.qtd[this.questionThreeId][0].split("~")[index];
         commentAns = this.qtd[this.commentId][0].split("~")[index];
        }

        // Account Type
        let accountType = this.accountTypeOptions.filter(
          (o) => o.label == accountTypeAns
        );
        let _accountType = accountType.length > 0 ? accountType[0].id : "Other";
        if(_accountType==='Other' && accountTypeAns==""){
          _accountType="";
        }
        let _otherAccountName = accountType.length > 0 ? "" : accountTypeAns;

        // Type
        let fireAndSmokeDamperType = this.typeOption.filter(
          (o) => o.label == typeIdAns
        );
        let _fireSmokeDamperType =
          fireAndSmokeDamperType.length > 0
            ? fireAndSmokeDamperType[0].id
            : "Other";
        if(_fireSmokeDamperType==='Other' && typeIdAns==""){
          _fireSmokeDamperType="";
        }
        let _otherFireSmokeDamper =
          fireAndSmokeDamperType.length > 0 ? "" : typeIdAns;

        let _damperData: FireAndSmokeDamper = {
          id: damperIdAns,
          accountType: _accountType,
          otherAccountType: _otherAccountName,
          fireAndDamperType: _fireSmokeDamperType,
          otherFireAndDamperType: _otherFireSmokeDamper,
          location: locationAns,
          testOne: q1Ans,
          testTwo: q2Ans,
          testThree: q3Ans,
          comments: commentAns,
        };

        this.addNewRow(_damperData);
      });
    } else {
      //Add New Row
      this.addNewRow();
    }
  }

  //#endregion

  //#region Public Functions

  addNewRow(data?: FireAndSmokeDamper) {

    this.isError = false

    let _data: FireAndSmokeDamper = data
      ? data
      : {
          id: "",
          accountType: "",
          otherAccountType: "",
          fireAndDamperType: "",
          otherFireAndDamperType: "",
          location: "",
          comments: "",
          testOne: "",
          testTwo: "",
          testThree: "",
        };

    this.fireAndSmokeTable.push(_data);
    this.bindWithQTDData();
  }

  removeRow(index) {
    if (this.fireAndSmokeTable.length > 1) {
      this.fireAndSmokeTable.splice(index, 1);

      Object.keys(this.qtd).forEach(key => {
        if (key.indexOf("firesmokedamper") > 0){
            delete this.qtd[key]
        }
      })

      this.bindWithQTDData()

    }
  }
  getMatchingKeysById(questionId: string): string[] {
    // Extract keys from qtd that match the given questionId pattern
    return Object.keys(this.qtd).filter(key => 
      key.startsWith(questionId + "~firesmokedamper~")
    );
  }

  updateModel(question, id, obj, key) {
    if (key === "accountType" && obj[key].toLowerCase() === "other") {
      this.qtd[this.findQuestionId(question) + "~firesmokedamper~" + id + "~"] =
        JSON.parse(JSON.stringify(this.fireAndSmokeTable[id].otherAccountType));
    } else if (
      key === "fireAndDamperType" &&
      obj[key].toLowerCase() === "other"
    ) {
      this.qtd[this.findQuestionId(question) + "~firesmokedamper~" + id + "~"] =
        JSON.parse(
          JSON.stringify(this.fireAndSmokeTable[id].otherFireAndDamperType)
        );
    } else {
      this.qtd[this.findQuestionId(question) + "~firesmokedamper~" + id + "~"] =
        JSON.parse(JSON.stringify(obj[key]));
    }

    this.showErrorMessage = false;
    this.isErrorInTable = false;
    console.log(this.qtd);
  }

  selectBinderCheckbox() {
    let radioQuestion = this.findQuestionId("Binder left on-site?");
    this.qtd[radioQuestion] = this.binderQuestion;
  }

  ngOnDestroy() {

    console.log("Distructor Called");

    this.inspectionService.setErrorInFireAndDamper(false);
    this.isErrorInTable = false;
    this.isError = false;
    this.showErrorMessage = false;

    this._subscriptions.forEach((o) => o.unsubscribe());
  }

  //#endregion
}
