import { ElementRef, OnChanges, OnDestroy, OnInit } from "@angular/core";
var FireSmokeDamperComponent = /** @class */ (function () {
    function FireSmokeDamperComponent(inspectionService) {
        this.inspectionService = inspectionService;
        this.fireAndSmokeTable = [];
        this.accountTypeOptions = [];
        this.typeOption = [];
        this.isError = false;
        this.showErrorMessage = false;
        this.isErrorInTable = false;
        //#region Private Variables
        this._subscriptions = [];
    }
    //#endregion
    FireSmokeDamperComponent.prototype.ngOnInit = function () {
        if (!this.reviewInspection) {
            this.setQuestionIds();
            this.setAccountTypeMasterData();
            this.setTypeMasterData();
            this.buildQuestionModel();
        }
        this.enableSubscriptions();
    };
    FireSmokeDamperComponent.prototype.ngOnChanges = function () {
        if (this.reviewInspection) {
            this.setQuestionIds();
            this.setAccountTypeMasterData();
            this.setTypeMasterData();
            this.buildQuestionModel();
        }
    };
    //#region Auxilary Functions
    FireSmokeDamperComponent.prototype.findQuestionId = function (question) {
        var ques = this.questions.filter(function (o) { return o.questions && o.questions.question == question; });
        if (ques.length > 0 && ques[0] && ques[0].questions) {
            return ques[0].questions.questionId;
        }
        return null;
    };
    FireSmokeDamperComponent.prototype.setQuestionIds = function () {
        this.damperQuestionId = this.findQuestionId("Damper ID#:");
        this.accountTypeId = this.findQuestionId("Acc. Type:");
        this.typeId = this.findQuestionId("Type:");
        this.locationId = this.findQuestionId("Location:");
        this.questionOneId = this.findQuestionId("Test 1");
        this.questionTwoId = this.findQuestionId("Test 2");
        this.questionThreeId = this.findQuestionId("Test 3");
        this.commentId = this.findQuestionId("Comments:");
        this.binderQuestionId = this.findQuestionId("Binder left on-site?");
    };
    FireSmokeDamperComponent.prototype.setAccountTypeMasterData = function () {
        var _this = this;
        this.accountTypeOptions = [];
        this.accountTypeOptions.push({
            id: "",
            label: "select",
            isSelected: true,
        });
        var accountTypeOptionQuestion = this.questions.filter(function (o) { return o.questions && o.questions.question === "Acc. Type:"; });
        if (accountTypeOptionQuestion.length > 0 &&
            accountTypeOptionQuestion[0] &&
            accountTypeOptionQuestion[0].questions &&
            accountTypeOptionQuestion[0].questions.options) {
            accountTypeOptionQuestion[0].questions.options.forEach(function (o) {
                _this.accountTypeOptions.push({
                    id: o["data"],
                    label: o["data"],
                    isSelected: false,
                });
            });
        }
    };
    FireSmokeDamperComponent.prototype.setTypeMasterData = function () {
        var _this = this;
        this.typeOption = [];
        this.typeOption.push({
            id: "",
            label: "select",
            isSelected: true,
        });
        var typeOptionQuestion = this.questions.filter(function (o) { return o.questions && o.questions.question === "Type:"; });
        if (typeOptionQuestion.length > 0 &&
            typeOptionQuestion[0] &&
            typeOptionQuestion[0].questions &&
            typeOptionQuestion[0].questions.options) {
            typeOptionQuestion[0].questions.options.forEach(function (o) {
                _this.typeOption.push({
                    id: o["data"],
                    label: o["data"],
                    isSelected: false,
                });
            });
        }
    };
    FireSmokeDamperComponent.prototype.enableSubscriptions = function () {
        var _this = this;
        this._subscriptions.push(this.inspectionService.getErrorInfireAndDamper().subscribe(function (res) {
            _this.isError = res;
            _this.showErrorMessage = res;
            var tableEmpty = _this.fireAndSmokeTable.filter(function (o) {
                return !(o.id &&
                    o.accountType &&
                    o.fireAndDamperType &&
                    o.location &&
                    o.testOne &&
                    o.testTwo &&
                    o.testThree);
            });
            if (tableEmpty.length > 0) {
                _this.isErrorInTable = res;
            }
        }));
    };
    FireSmokeDamperComponent.prototype.bindWithQTDData = function () {
        var _this = this;
        this.fireAndSmokeTable.forEach(function (o, i) {
            if (_this.damperQuestionId)
                _this.qtd[_this.damperQuestionId + "~firesmokedamper~" + i + "~"] = o.id;
            if (_this.accountTypeId)
                _this.qtd[_this.accountTypeId + "~firesmokedamper~" + i + "~"] =
                    o.otherAccountType != "" ? o.otherAccountType : o.accountType;
            if (_this.typeId)
                _this.qtd[_this.typeId + "~firesmokedamper~" + i + "~"] =
                    o.otherFireAndDamperType != ""
                        ? o.otherFireAndDamperType
                        : o.fireAndDamperType;
            if (_this.locationId)
                _this.qtd[_this.locationId + "~firesmokedamper~" + i + "~"] = o.location;
            if (_this.questionOneId)
                _this.qtd[_this.questionOneId + "~firesmokedamper~" + i + "~"] = o.testOne;
            if (_this.questionTwoId)
                _this.qtd[_this.questionTwoId + "~firesmokedamper~" + i + "~"] = o.testTwo;
            if (_this.questionThreeId)
                _this.qtd[_this.questionThreeId + "~firesmokedamper~" + i + "~"] = o.testThree;
            if (_this.commentId)
                _this.qtd[_this.commentId + "~firesmokedamper~" + i + "~"] = o.comments;
        });
    };
    FireSmokeDamperComponent.prototype.buildQuestionModel = function () {
        var _this = this;
        //object empty check
        if (Object.keys(this.qtd).length == 0 && this.questions.length == 0)
            return;
        // set binder question
        if (this.qtd &&
            this.qtd[this.binderQuestionId] &&
            this.qtd[this.binderQuestionId][0]) {
            this.binderQuestion = this.qtd[this.binderQuestionId].toString();
        }
        // Table Setup
        var totalTableRows = this.qtd[this.damperQuestionId]
            ? this.qtd[this.damperQuestionId][0].split("~")
            : [];
        if (totalTableRows.length == 0) {
            totalTableRows = this.getMatchingKeysById(this.damperQuestionId.toString());
        }
        if (totalTableRows.length > 0) {
            totalTableRows.forEach(function (element, index) {
                var damperIdAns;
                var accountTypeAns;
                var typeIdAns;
                var locationAns;
                var q1Ans;
                var q2Ans;
                var q3Ans;
                var commentAns;
                if (_this.qtd[_this.damperQuestionId + "~firesmokedamper~" + index + "~"] != null) {
                    damperIdAns = _this.qtd[_this.damperQuestionId + "~firesmokedamper~" + index + "~"];
                    accountTypeAns = _this.qtd[_this.accountTypeId + "~firesmokedamper~" + index + "~"];
                    typeIdAns = _this.qtd[_this.typeId + "~firesmokedamper~" + index + "~"];
                    locationAns = _this.qtd[_this.locationId + "~firesmokedamper~" + index + "~"];
                    q1Ans = _this.qtd[_this.questionOneId + "~firesmokedamper~" + index + "~"];
                    q2Ans = _this.qtd[_this.questionTwoId + "~firesmokedamper~" + index + "~"];
                    q3Ans = _this.qtd[_this.questionThreeId + "~firesmokedamper~" + index + "~"];
                    commentAns = _this.qtd[_this.commentId + "~firesmokedamper~" + index + "~"];
                }
                else {
                    damperIdAns = _this.qtd[_this.damperQuestionId][0].split("~")[index];
                    accountTypeAns = _this.qtd[_this.accountTypeId][0].split("~")[index];
                    typeIdAns = _this.qtd[_this.typeId][0].split("~")[index];
                    locationAns = _this.qtd[_this.locationId][0].split("~")[index];
                    q1Ans = _this.qtd[_this.questionOneId][0].split("~")[index];
                    q2Ans = _this.qtd[_this.questionTwoId][0].split("~")[index];
                    q3Ans = _this.qtd[_this.questionThreeId][0].split("~")[index];
                    commentAns = _this.qtd[_this.commentId][0].split("~")[index];
                }
                // Account Type
                var accountType = _this.accountTypeOptions.filter(function (o) { return o.label == accountTypeAns; });
                var _accountType = accountType.length > 0 ? accountType[0].id : "Other";
                if (_accountType === 'Other' && accountTypeAns == "") {
                    _accountType = "";
                }
                var _otherAccountName = accountType.length > 0 ? "" : accountTypeAns;
                // Type
                var fireAndSmokeDamperType = _this.typeOption.filter(function (o) { return o.label == typeIdAns; });
                var _fireSmokeDamperType = fireAndSmokeDamperType.length > 0
                    ? fireAndSmokeDamperType[0].id
                    : "Other";
                if (_fireSmokeDamperType === 'Other' && typeIdAns == "") {
                    _fireSmokeDamperType = "";
                }
                var _otherFireSmokeDamper = fireAndSmokeDamperType.length > 0 ? "" : typeIdAns;
                var _damperData = {
                    id: damperIdAns,
                    accountType: _accountType,
                    otherAccountType: _otherAccountName,
                    fireAndDamperType: _fireSmokeDamperType,
                    otherFireAndDamperType: _otherFireSmokeDamper,
                    location: locationAns,
                    testOne: q1Ans,
                    testTwo: q2Ans,
                    testThree: q3Ans,
                    comments: commentAns,
                };
                _this.addNewRow(_damperData);
            });
        }
        else {
            //Add New Row
            this.addNewRow();
        }
    };
    //#endregion
    //#region Public Functions
    FireSmokeDamperComponent.prototype.addNewRow = function (data) {
        this.isError = false;
        var _data = data
            ? data
            : {
                id: "",
                accountType: "",
                otherAccountType: "",
                fireAndDamperType: "",
                otherFireAndDamperType: "",
                location: "",
                comments: "",
                testOne: "",
                testTwo: "",
                testThree: "",
            };
        this.fireAndSmokeTable.push(_data);
        this.bindWithQTDData();
    };
    FireSmokeDamperComponent.prototype.removeRow = function (index) {
        var _this = this;
        if (this.fireAndSmokeTable.length > 1) {
            this.fireAndSmokeTable.splice(index, 1);
            Object.keys(this.qtd).forEach(function (key) {
                if (key.indexOf("firesmokedamper") > 0) {
                    delete _this.qtd[key];
                }
            });
            this.bindWithQTDData();
        }
    };
    FireSmokeDamperComponent.prototype.getMatchingKeysById = function (questionId) {
        // Extract keys from qtd that match the given questionId pattern
        return Object.keys(this.qtd).filter(function (key) {
            return key.startsWith(questionId + "~firesmokedamper~");
        });
    };
    FireSmokeDamperComponent.prototype.updateModel = function (question, id, obj, key) {
        if (key === "accountType" && obj[key].toLowerCase() === "other") {
            this.qtd[this.findQuestionId(question) + "~firesmokedamper~" + id + "~"] =
                JSON.parse(JSON.stringify(this.fireAndSmokeTable[id].otherAccountType));
        }
        else if (key === "fireAndDamperType" &&
            obj[key].toLowerCase() === "other") {
            this.qtd[this.findQuestionId(question) + "~firesmokedamper~" + id + "~"] =
                JSON.parse(JSON.stringify(this.fireAndSmokeTable[id].otherFireAndDamperType));
        }
        else {
            this.qtd[this.findQuestionId(question) + "~firesmokedamper~" + id + "~"] =
                JSON.parse(JSON.stringify(obj[key]));
        }
        this.showErrorMessage = false;
        this.isErrorInTable = false;
        console.log(this.qtd);
    };
    FireSmokeDamperComponent.prototype.selectBinderCheckbox = function () {
        var radioQuestion = this.findQuestionId("Binder left on-site?");
        this.qtd[radioQuestion] = this.binderQuestion;
    };
    FireSmokeDamperComponent.prototype.ngOnDestroy = function () {
        console.log("Distructor Called");
        this.inspectionService.setErrorInFireAndDamper(false);
        this.isErrorInTable = false;
        this.isError = false;
        this.showErrorMessage = false;
        this._subscriptions.forEach(function (o) { return o.unsubscribe(); });
    };
    return FireSmokeDamperComponent;
}());
export { FireSmokeDamperComponent };
