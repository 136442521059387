import { Component, Input, OnInit } from '@angular/core';
import { HeadingQuestion } from '../../../core/models/inspection/inspection.model';
import { InspectionService } from '../../../core/services/inspection/inspection.service';

@Component({
  selector: 'app-standpipe-prv',
  templateUrl: './standpipe-prv.component.html',
  styleUrls: ['./standpipe-prv.component.scss']
})
export class StandpipePrvComponent implements OnInit {

  prv: any;
  @Input() questions: Array<HeadingQuestion>;
  @Input() qtd: Array<any>;
  @Input() reviewInspection: boolean;
  constructor(private insService: InspectionService) { }

  ngOnInit() {
    this.prv = [{ id: 0 }];
    if (this.insService.getStandPipeRows() > 0) {
      for (let i = 1; i < this.insService.getStandPipeRows(); i++) {
        this.prv.push({ id: i });
      }
    }
    if (this.insService.getPVRRows() && this.insService.getPVRRows().length > 0) {
      this.prv = this.insService.getPVRRows();
      if(this.qtd[this.findQuestionIdSecond('Floor/Stair') + '~standpipe~' + 0 + '~']==null){
      for (let m = 0; m < this.prv.length; m++) {
        this.qtd[this.findQuestionIdSecond('Floor/Stair') + '~standpipe~' + m + '~'] =
          JSON.parse(JSON.stringify(this.prv[m]['Floor/Stair'])) || '';
        this.qtd[this.findQuestionIdSecond('Inlet-Static(psi)') + '~standpipe~' + m + '~'] =
          JSON.parse(JSON.stringify(this.prv[m]['Inlet-Static(psi)'])) || '';
        this.qtd[this.findQuestionIdSecond('Outlet-Static(psi)') + '~standpipe~' + m + '~'] =
          JSON.parse(JSON.stringify(this.prv[m]['Outlet-Static(psi)'])) || '';
        this.qtd[this.findQuestionIdSecond('Inlet-Residual(psi)') + '~standpipe~' + m + '~'] =
          JSON.parse(JSON.stringify(this.prv[m]['Inlet-Residual(psi)'])) || '';
        this.qtd[this.findQuestionIdSecond('Outlet-Residual(psi)') + '~standpipe~' + m + '~'] =
          JSON.parse(JSON.stringify(this.prv[m]['Outlet-Residual(psi)'])) || '';
        this.qtd[this.findQuestionIdSecond('Outlet-Flow(gpm)') + '~standpipe~' + m + '~'] =
          JSON.parse(JSON.stringify(this.prv[m]['Outlet-Flow(gpm)'])) || '';
        this.qtd[this.findQuestionIdSecond('P.R.V.Setting(psi)') + '~standpipe~' + m + '~'] =
          JSON.parse(JSON.stringify(this.prv[m]['P.R.V.Setting(psi)'])) || '';
      }
    }
    } else {
      if (this.insService.getStandPipeRows() <= 0) {
        this.prv = [{ id: 0 }];
      }
    }
  }

  addRow() {
    this.prv.push({ id: this.prv.length });
    this.insService.setStandPipeRows(this.prv.length);
  }

  deleteRow(index) {
    this.prv = this.prv.filter((x) => x.id !== index.id);
    for (const key of Object.keys(this.qtd)) {
      if (key.split('~') && key.indexOf('standpipe') > 0) {
        delete this.qtd[key];
      }
    }
    this.rebuildDOM();
    this.insService.setStandPipeRows(this.prv.length);
  }

  findQuestionIdSecond(description?) {
    if (this.questions) {
      if (this.questions.filter(x => x.questions.question === description) &&
        this.questions.filter(x => x.questions.question === description)[0]) {
        return this.questions.filter(x => x.questions.question === description)[0].questions.questionId;
      }
    }
    return 0;

  }

  updateModel(question, id, k) {
    this.qtd[this.findQuestionIdSecond(question) + '~standpipe~' + id + '~'] = JSON.parse(JSON.stringify(k[question]));
  }

  rebuildDOM() {
    for (let m = 0; m < this.prv.length; m++) {
      this.qtd[this.findQuestionIdSecond('Floor/Stair') + '~standpipe~' + m + '~'] =
        this.prv[m]['Floor/Stair'] ? JSON.parse(JSON.stringify(this.prv[m]['Floor/Stair'])) : '';
      this.qtd[this.findQuestionIdSecond('Inlet-Static(psi)') + '~standpipe~' + m + '~'] =
        this.prv[m]['Inlet-Static(psi)'] ? JSON.parse(JSON.stringify(this.prv[m]['Inlet-Static(psi)'])) : '';
      this.qtd[this.findQuestionIdSecond('Outlet-Static(psi)') + '~standpipe~' + m + '~'] =
        this.prv[m]['Outlet-Static(psi)'] ? JSON.parse(JSON.stringify(this.prv[m]['Outlet-Static(psi)'])) : '';
      this.qtd[this.findQuestionIdSecond('Inlet-Residual(psi)') + '~standpipe~' + m + '~'] =
        this.prv[m]['Inlet-Residual(psi)'] ? JSON.parse(JSON.stringify(this.prv[m]['Inlet-Residual(psi)'])) : '';
      this.qtd[this.findQuestionIdSecond('Outlet-Residual(psi)') + '~standpipe~' + m + '~'] =
        this.prv[m]['Outlet-Residual(psi)'] ? JSON.parse(JSON.stringify(this.prv[m]['Outlet-Residual(psi)'])) : '';
      this.qtd[this.findQuestionIdSecond('Outlet-Flow(gpm)') + '~standpipe~' + m + '~'] =
        this.prv[m]['Outlet-Flow(gpm)'] ? JSON.parse(JSON.stringify(this.prv[m]['Outlet-Flow(gpm)'])) : '';
      this.qtd[this.findQuestionIdSecond('P.R.V.Setting(psi)') + '~standpipe~' + m + '~'] =
        this.prv[m]['P.R.V.Setting(psi)'] ? JSON.parse(JSON.stringify(this.prv[m]['P.R.V.Setting(psi)'])) : '';
    }
  }
}
