<div id="FireAlarmStairwayDoorLockDiv">
  <table class="sample" width="100%">
    <tbody>
      <tr class="GridRowGray">
        <td align="center" width="25%">System Devices</td>
        <td align="center" width="20%">Total Number of Units in
          Building</td>
        <td align="center" width="20%">Total Number Units Tested</td>
        <td align="center" width="30%">Test Results Acceptable</td>
      </tr>
      <tr>
        <td>Electric Strike</td>
        <td align="center">
          <table class="sample1">
            <tbody>
              <tr>
                <td class="tdLabel"></td>
                <td><input type="text" name="reportDataDto.stairwayDoorLocks[0].totalUnits" size="10" value=""
                    id="reviewInspectionForm_reportDataDto_stairwayDoorLocks_0__totalUnits"
                    [(ngModel)]="qtd[findQuestionId('Electric Strike')]['unitsInBuilding']"
                    (change)="updateModel('Electric Strike', 'unitsInBuilding', $event.target.value)"
                    [disabled]="reviewInspection"></td>
              </tr>
            </tbody>
          </table>
        </td>
        <td align="center">
          <table class="sample1">
            <tbody>
              <tr>
                <td class="tdLabel"></td>
                <td><input type="text" name="reportDataDto.stairwayDoorLocks[0].totalUnitsTested" size="10" value=""
                    id="reviewInspectionForm_reportDataDto_stairwayDoorLocks_0__totalUnitsTested"
                    [(ngModel)]="qtd[findQuestionId('Electric Strike')]['unitsTested']"
                    (change)="updateModel('Electric Strike', 'unitsTested', $event.target.value)"
                    [disabled]="reviewInspection"></td>
              </tr>
            </tbody>
          </table>
        </td>
        <td align="center">
          <table class="sample1">
            <tbody>
              <tr>
              </tr>
              <tr>
                <td class="tdLabel"></td>
                <td>
                  <mat-radio-group aria-label="Select an option"
                  [(ngModel)]="qtd[findQuestionId('Electric Strike')]['resultsAcceptable']">
                    <mat-radio-button name="reportDataDto.stairwayDoorLocks[0].resultsAccepted"
                    id="reviewInspectionForm_reportDataDto_stairwayDoorLocks_0__resultsAcceptedN/A"
                    value="N/A"
                    (change)="updateModel('Electric Strike', 'resultsAcceptable', $event.value)"
                    [disabled]="reviewInspection">N/A</mat-radio-button>
                    <mat-radio-button name="reportDataDto.stairwayDoorLocks[0].resultsAccepted"
                    id="reviewInspectionForm_reportDataDto_stairwayDoorLocks_0__resultsAcceptedYes" value="Yes"
                    (change)="updateModel('Electric Strike', 'resultsAcceptable', $event.value)"
                    [disabled]="reviewInspection">Yes</mat-radio-button>
                    <mat-radio-button name="reportDataDto.stairwayDoorLocks[0].resultsAccepted"
                    id="reviewInspectionForm_reportDataDto_stairwayDoorLocks_0__resultsAcceptedNo" value="No"
                    (change)="updateModel('Electric Strike', 'resultsAcceptable', $event.value)"
                    [disabled]="reviewInspection">No</mat-radio-button>
                  </mat-radio-group>

                  <!-- <input type="radio" name="reportDataDto.stairwayDoorLocks[0].resultsAccepted"
                    id="reviewInspectionForm_reportDataDto_stairwayDoorLocks_0__resultsAcceptedN/A" checked="checked"
                    value="N/A" [(ngModel)]="stairwayTable.electricStrike.resultsAcceptable"
                    (change)="updateModel('Electric Strike', 'resultsAcceptable', $event.target.value)"
                    [disabled]="reviewInspection"><label
                    for="reviewInspectionForm_reportDataDto_stairwayDoorLocks_0__resultsAcceptedN/A">N/A</label>
                  <input type="radio" name="reportDataDto.stairwayDoorLocks[0].resultsAccepted"
                    id="reviewInspectionForm_reportDataDto_stairwayDoorLocks_0__resultsAcceptedYes" value="Yes"
                    [(ngModel)]="stairwayTable.electricStrike.resultsAcceptable"
                    (change)="updateModel('Electric Strike', 'resultsAcceptable', $event.target.value)"
                    [disabled]="reviewInspection"><label
                    for="reviewInspectionForm_reportDataDto_stairwayDoorLocks_0__resultsAcceptedYes">Yes</label>
                  <input type="radio" name="reportDataDto.stairwayDoorLocks[0].resultsAccepted"
                    id="reviewInspectionForm_reportDataDto_stairwayDoorLocks_0__resultsAcceptedNo" value="No"
                    [(ngModel)]="stairwayTable.electricStrike.resultsAcceptable"
                    (change)="updateModel('Electric Strike', 'resultsAcceptable', $event.target.value)"
                    [disabled]="reviewInspection"><label
                    for="reviewInspectionForm_reportDataDto_stairwayDoorLocks_0__resultsAcceptedNo">No</label> -->
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
      <tr>
        <td>Electric Bolt</td>
        <td align="center">
          <table class="sample1">
            <tbody>
              <tr>
                <td class="tdLabel"></td>
                <td><input type="text" name="reportDataDto.stairwayDoorLocks[1].totalUnits" size="10" value=""
                    id="reviewInspectionForm_reportDataDto_stairwayDoorLocks_1__totalUnits"
                    [(ngModel)]="qtd[findQuestionId('Electric Bolt')]['unitsInBuilding']"
                    (change)="updateModel('Electric Bolt', 'unitsInBuilding', $event.target.value)"
                    [disabled]="reviewInspection"></td>
              </tr>
            </tbody>
          </table>
        </td>
        <td align="center">
          <table class="sample1">
            <tbody>
              <tr>
                <td class="tdLabel"></td>
                <td><input type="text" name="reportDataDto.stairwayDoorLocks[1].totalUnitsTested" size="10" value=""
                    id="reviewInspectionForm_reportDataDto_stairwayDoorLocks_1__totalUnitsTested"
                    [(ngModel)]="qtd[findQuestionId('Electric Bolt')]['unitsTested']"
                    (change)="updateModel('Electric Bolt', 'unitsTested', $event.target.value)"
                    [disabled]="reviewInspection"></td>
              </tr>
            </tbody>
          </table>
        </td>
        <td align="center">
          <table class="sample1">
            <tbody>
              <tr>
              </tr>
              <tr>
                <td class="tdLabel"></td>
                <td>

                  <mat-radio-group aria-label="Select an option"
                  [(ngModel)]="qtd[findQuestionId('Electric Bolt')]['resultsAcceptable']">
                    <mat-radio-button name="reportDataDto.stairwayDoorLocks[1].resultsAccepted"
                    id="reviewInspectionForm_reportDataDto_stairwayDoorLocks_1__resultsAcceptedN/A"
                    value="N/A"
                    (change)="updateModel('Electric Bolt', 'resultsAcceptable', $event.value)"
                    [disabled]="reviewInspection">N/A</mat-radio-button>
                    <mat-radio-button name="reportDataDto.stairwayDoorLocks[1].resultsAccepted"
                    id="reviewInspectionForm_reportDataDto_stairwayDoorLocks_1__resultsAcceptedYes" value="Yes"
                    (change)="updateModel('Electric Bolt', 'resultsAcceptable', $event.value)"
                    [disabled]="reviewInspection">Yes</mat-radio-button>
                    <mat-radio-button name="reportDataDto.stairwayDoorLocks[1].resultsAccepted"
                    id="reviewInspectionForm_reportDataDto_stairwayDoorLocks_1__resultsAcceptedNo" value="No"
                    (change)="updateModel('Electric Bolt', 'resultsAcceptable', $event.value)"
                    [disabled]="reviewInspection">No</mat-radio-button>
                  </mat-radio-group>

                  <!-- <input type="radio" name="reportDataDto.stairwayDoorLocks[1].resultsAccepted"
                    id="reviewInspectionForm_reportDataDto_stairwayDoorLocks_1__resultsAcceptedN/A" checked="checked"
                    value="N/A" [(ngModel)]="stairwayTable.electricBolt.resultsAcceptable"
                    (change)="updateModel('Electric Bolt', 'resultsAcceptable', $event.target.value)"
                    [disabled]="reviewInspection"><label
                    for="reviewInspectionForm_reportDataDto_stairwayDoorLocks_1__resultsAcceptedN/A">N/A</label>
                  <input type="radio" name="reportDataDto.stairwayDoorLocks[1].resultsAccepted"
                    id="reviewInspectionForm_reportDataDto_stairwayDoorLocks_1__resultsAcceptedYes" value="Yes"
                    [(ngModel)]="stairwayTable.electricBolt.resultsAcceptable"
                    (change)="updateModel('Electric Bolt', 'resultsAcceptable', $event.target.value)"
                    [disabled]="reviewInspection"><label
                    for="reviewInspectionForm_reportDataDto_stairwayDoorLocks_1__resultsAcceptedYes">Yes</label>
                  <input type="radio" name="reportDataDto.stairwayDoorLocks[1].resultsAccepted"
                    id="reviewInspectionForm_reportDataDto_stairwayDoorLocks_1__resultsAcceptedNo" value="No"
                    [(ngModel)]="stairwayTable.electricBolt.resultsAcceptable"
                    (change)="updateModel('Electric Bolt', 'resultsAcceptable', $event.target.value)"
                    [disabled]="reviewInspection"><label
                    for="reviewInspectionForm_reportDataDto_stairwayDoorLocks_1__resultsAcceptedNo">No</label> -->
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
      <tr>
        <td>Other Locking Devices</td>
        <td align="center">
          <table class="sample1">
            <tbody>
              <tr>
                <td class="tdLabel"></td>
                <td><input type="text" name="reportDataDto.stairwayDoorLocks[2].totalUnits" size="10" value=""
                    id="reviewInspectionForm_reportDataDto_stairwayDoorLocks_2__totalUnits"
                    [(ngModel)]="qtd[findQuestionId('Other Locking Devices')]['unitsInBuilding']"
                    (change)="updateModel('Other Locking Devices', 'unitsInBuilding', $event.target.value)"
                    [disabled]="reviewInspection"></td>
              </tr>
            </tbody>
          </table>
        </td>
        <td align="center">
          <table class="sample1">
            <tbody>
              <tr>
                <td class="tdLabel"></td>
                <td><input type="text" name="reportDataDto.stairwayDoorLocks[2].totalUnitsTested" size="10" value=""
                    id="reviewInspectionForm_reportDataDto_stairwayDoorLocks_2__totalUnitsTested"
                    [(ngModel)]="qtd[findQuestionId('Other Locking Devices')]['unitsTested']"
                    (change)="updateModel('Other Locking Devices', 'unitsTested', $event.target.value)"
                    [disabled]="reviewInspection"></td>
              </tr>
            </tbody>
          </table>
        </td>
        <td align="center">
          <table class="sample1">
            <tbody>
              <tr>
              </tr>
              <tr>
                <td class="tdLabel"></td>
                <td>

                  <mat-radio-group aria-label="Select an option"
                  [(ngModel)]="qtd[findQuestionId('Other Locking Devices')]['resultsAcceptable']">
                    <mat-radio-button name="reportDataDto.stairwayDoorLocks[2].resultsAccepted"
                    id="reviewInspectionForm_reportDataDto_stairwayDoorLocks_2__resultsAcceptedN/A"
                    value="N/A" 
                    (change)="updateModel('Other Locking Devices', 'resultsAcceptable', $event.value)"
                    [disabled]="reviewInspection">N/A</mat-radio-button>
                    <mat-radio-button name="reportDataDto.stairwayDoorLocks[2].resultsAccepted"
                    id="reviewInspectionForm_reportDataDto_stairwayDoorLocks_2__resultsAcceptedYes" value="Yes"
                    (change)="updateModel('Other Locking Devices', 'resultsAcceptable', $event.value)"
                    [disabled]="reviewInspection">Yes</mat-radio-button>
                    <mat-radio-button name="reportDataDto.stairwayDoorLocks[2].resultsAccepted"
                    id="reviewInspectionForm_reportDataDto_stairwayDoorLocks_2__resultsAcceptedNo" value="No"
                    (change)="updateModel('Other Locking Devices', 'resultsAcceptable', $event.value)"
                    [disabled]="reviewInspection">No</mat-radio-button>
                  </mat-radio-group>

                  <!-- <input type="radio" name="reportDataDto.stairwayDoorLocks[2].resultsAccepted"
                    id="reviewInspectionForm_reportDataDto_stairwayDoorLocks_2__resultsAcceptedN/A" checked="checked"
                    value="N/A" [(ngModel)]="stairwayTable.otherLockingDevices.resultsAcceptable"
                    (change)="updateModel('Other Locking Devices', 'resultsAcceptable', $event.target.value)"
                    [disabled]="reviewInspection"><label
                    for="reviewInspectionForm_reportDataDto_stairwayDoorLocks_2__resultsAcceptedN/A">N/A</label>
                  <input type="radio" name="reportDataDto.stairwayDoorLocks[2].resultsAccepted"
                    id="reviewInspectionForm_reportDataDto_stairwayDoorLocks_2__resultsAcceptedYes" value="Yes"
                    [(ngModel)]="stairwayTable.otherLockingDevices.resultsAcceptable"
                    (change)="updateModel('Other Locking Devices', 'resultsAcceptable', $event.target.value)"
                    [disabled]="reviewInspection"><label
                    for="reviewInspectionForm_reportDataDto_stairwayDoorLocks_2__resultsAcceptedYes">Yes</label>
                  <input type="radio" name="reportDataDto.stairwayDoorLocks[2].resultsAccepted"
                    id="reviewInspectionForm_reportDataDto_stairwayDoorLocks_2__resultsAcceptedNo" value="No"
                    [(ngModel)]="stairwayTable.otherLockingDevices.resultsAcceptable"
                    (change)="updateModel('Other Locking Devices', 'resultsAcceptable', $event.target.value)"
                    [disabled]="reviewInspection"><label
                    for="reviewInspectionForm_reportDataDto_stairwayDoorLocks_2__resultsAcceptedNo">No</label> -->
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    </tbody>
  </table>
</div>
