<div id="FireAlarmCommunicationEquipmentDiv">
  <table class="sample" width="100%">
    <tbody>
      <tr class="GridRowGray">
        <td align="center" width="25%">Communication Equipment</td>
        <td align="center" width="20%">Total Number of Units in Building</td>
        <td align="center" width="20%">Total Number Units Tested</td>
        <td align="center" width="30%">Test Results Acceptable</td>
      </tr>
      <tr>
        <td>Phone</td>
        <td align="center">
          <table class="sample1">
            <tbody>
              <tr>
                <td class="tdLabel"></td>
                <td><input type="text" name="reportDataDto.communicationEquipments[0].totalUnits" size="10" value=""
                    id="reviewInspectionForm_reportDataDto_communicationEquipments_0__totalUnits"
                    [(ngModel)]="qtd[findQuestionId('Phone')]['unitsInBuilding']"
                    (change)="updateModel('Phone', 'unitsInBuilding', $event.target.value)"
                    [disabled]="reviewInspection"></td>
              </tr>
            </tbody>
          </table>
        </td>
        <td align="center">
          <table class="sample1">
            <tbody>
              <tr>
                <td class="tdLabel"></td>
                <td><input type="text" name="reportDataDto.communicationEquipments[0].totalUnitsTested" size="10"
                    value="" id="reviewInspectionForm_reportDataDto_communicationEquipments_0__totalUnitsTested"
                    [(ngModel)]="qtd[findQuestionId('Phone')]['unitsTested']"
                    (change)="updateModel('Phone', 'unitsTested', $event.target.value)"
                    [disabled]="reviewInspection"></td>
              </tr>
            </tbody>
          </table>
        </td>
        <td align=" center">
          <table class="sample1">
            <tbody>
              <tr>
              </tr>
              <tr>
                <td class="tdLabel"></td>
                <td>

                  <mat-radio-group aria-label="Select an option"
                  [(ngModel)]="qtd[findQuestionId('Phone')]['resultsAcceptable']"
                  >
                    <mat-radio-button name="reportDataDto.communicationEquipments[0].resultsAccepted"
                    id="reviewInspectionForm_reportDataDto_communicationEquipments_0__resultsAcceptedN/A"
                    value="N/A" 
                    (change)="updateModel('Phone', 'resultsAcceptable', $event.value)"
                    [disabled]="reviewInspection">N/A</mat-radio-button>
                    <mat-radio-button name="reportDataDto.communicationEquipments[0].resultsAccepted"
                    id="reviewInspectionForm_reportDataDto_communicationEquipments_0__resultsAcceptedYes" value="Yes"
                    (change)="updateModel('Phone', 'resultsAcceptable', $event.value)"
                    [disabled]="reviewInspection">Yes</mat-radio-button>
                    <mat-radio-button name="reportDataDto.communicationEquipments[0].resultsAccepted"
                    id="reviewInspectionForm_reportDataDto_communicationEquipments_0__resultsAcceptedNo" value="No"
                    (change)="updateModel('Phone', 'resultsAcceptable', $event.value)"
                    [disabled]="reviewInspection">No</mat-radio-button>
                  </mat-radio-group>

                  <!-- <input type="radio" name="reportDataDto.communicationEquipments[0].resultsAccepted"
                    id="reviewInspectionForm_reportDataDto_communicationEquipments_0__resultsAcceptedN/A"
                    checked="checked" value="N/A" 
                    [(ngModel)]="communicationTable.phone.resultsAcceptable"
                    (change)="updateModel('Phone', 'resultsAcceptable', $event.target.value)"
                    [disabled]="reviewInspection"><label
                    for="reviewInspectionForm_reportDataDto_communicationEquipments_0__resultsAcceptedN/A">N/A</label>
                  <input type="radio" name="reportDataDto.communicationEquipments[0].resultsAccepted"
                    id="reviewInspectionForm_reportDataDto_communicationEquipments_0__resultsAcceptedYes" value="Yes"
                    [(ngModel)]="communicationTable.phone.resultsAcceptable"
                    (change)="updateModel('Phone', 'resultsAcceptable', $event.target.value)"
                    [disabled]="reviewInspection"><label
                    for="reviewInspectionForm_reportDataDto_communicationEquipments_0__resultsAcceptedYes">Yes</label>
                  <input type="radio" name="reportDataDto.communicationEquipments[0].resultsAccepted"
                    id="reviewInspectionForm_reportDataDto_communicationEquipments_0__resultsAcceptedNo" value="No"
                    [(ngModel)]="communicationTable.phone.resultsAcceptable"
                    (change)="updateModel('Phone', 'resultsAcceptable', $event.target.value)"
                    [disabled]="reviewInspection"><label
                    for="reviewInspectionForm_reportDataDto_communicationEquipments_0__resultsAcceptedNo">No</label> -->
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
      <tr>
        <td>Cellular</td>
        <td align="center">
          <table class="sample1">
            <tbody>
              <tr>
                <td class="tdLabel"></td>
                <td><input type="text" name="reportDataDto.communicationEquipments[1].totalUnits" size="10" value=""
                    id="reviewInspectionForm_reportDataDto_communicationEquipments_1__totalUnits"
                    [(ngModel)]="qtd[findQuestionId('Cellular')]['unitsInBuilding']"
                    (change)="updateModel('Cellular', 'unitsInBuilding', $event.target.value)"
                    [disabled]="reviewInspection"></td>
              </tr>
            </tbody>
          </table>
        </td>
        <td align="center">
          <table class="sample1">
            <tbody>
              <tr>
                <td class="tdLabel"></td>
                <td><input type="text" name="reportDataDto.communicationEquipments[1].totalUnitsTested" size="10"
                    value="" id="reviewInspectionForm_reportDataDto_communicationEquipments_1__totalUnitsTested"
                    [(ngModel)]="qtd[findQuestionId('Cellular')]['unitsTested']"
                    (change)="updateModel('Cellular', 'unitsTested', $event.target.value)"
                    [disabled]="reviewInspection"></td>
              </tr>
            </tbody>
          </table>
        </td>
        <td align=" center">
          <table class="sample1">
            <tbody>
              <tr>
              </tr>
              <tr>
                <td class="tdLabel"></td>
                <td>

                  <mat-radio-group aria-label="Select an option"
                  [(ngModel)]="qtd[findQuestionId('Cellular')]['resultsAcceptable']"
                  >
                    <mat-radio-button name="reportDataDto.communicationEquipments[1].resultsAccepted"
                    id="reviewInspectionForm_reportDataDto_communicationEquipments_1__resultsAcceptedN/A"
                    value="N/A"
                    (change)="updateModel('Cellular', 'resultsAcceptable', $event.value)"
                    [disabled]="reviewInspection">N/A</mat-radio-button>
                    <mat-radio-button name="reportDataDto.communicationEquipments[1].resultsAccepted"
                    id="reviewInspectionForm_reportDataDto_communicationEquipments_1__resultsAcceptedYes" value="Yes"
                    (change)="updateModel('Cellular', 'resultsAcceptable', $event.value)"
                    [disabled]="reviewInspection">Yes</mat-radio-button>
                    <mat-radio-button name="reportDataDto.communicationEquipments[1].resultsAccepted"
                    id="reviewInspectionForm_reportDataDto_communicationEquipments_1__resultsAcceptedNo" value="No"
                    (change)="updateModel('Cellular', 'resultsAcceptable', $event.value)"
                    [disabled]="reviewInspection">No</mat-radio-button>
                  </mat-radio-group>

                  <!-- <input type="radio" name="reportDataDto.communicationEquipments[1].resultsAccepted"
                    id="reviewInspectionForm_reportDataDto_communicationEquipments_1__resultsAcceptedN/A"
                    checked="checked" value="N/A"
                    [(ngModel)]="communicationTable.cellular.resultsAcceptable"
                    (change)="updateModel('Cellular', 'resultsAcceptable', $event.target.value)"
                    [disabled]="reviewInspection"><label
                    for="reviewInspectionForm_reportDataDto_communicationEquipments_1__resultsAcceptedN/A">N/A</label>
                  <input type="radio" name="reportDataDto.communicationEquipments[1].resultsAccepted"
                    id="reviewInspectionForm_reportDataDto_communicationEquipments_1__resultsAcceptedYes" value="Yes"
                    [(ngModel)]="communicationTable.cellular.resultsAcceptable"
                    (change)="updateModel('Cellular', 'resultsAcceptable', $event.target.value)"
                    [disabled]="reviewInspection"><label
                    for="reviewInspectionForm_reportDataDto_communicationEquipments_1__resultsAcceptedYes">Yes</label>
                  <input type="radio" name="reportDataDto.communicationEquipments[1].resultsAccepted"
                    id="reviewInspectionForm_reportDataDto_communicationEquipments_1__resultsAcceptedNo" value="No"
                    [(ngModel)]="communicationTable.cellular.resultsAcceptable"
                    (change)="updateModel('Cellular', 'resultsAcceptable', $event.target.value)"
                    [disabled]="reviewInspection"><label
                    for="reviewInspectionForm_reportDataDto_communicationEquipments_1__resultsAcceptedNo">No</label> -->
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
      <tr>
        <td>Radio</td>
        <td align="center">
          <table class="sample1">
            <tbody>
              <tr>
                <td class="tdLabel"></td>
                <td><input type="text" name="reportDataDto.communicationEquipments[2].totalUnits" size="10" value=""
                    id="reviewInspectionForm_reportDataDto_communicationEquipments_2__totalUnits"
                    [(ngModel)]="qtd[findQuestionId('Radio')]['unitsInBuilding']"
                    (change)="updateModel('Radio', 'unitsInBuilding', $event.target.value)"
                    [disabled]="reviewInspection"></td>
              </tr>
            </tbody>
          </table>
        </td>
        <td align="center">
          <table class="sample1">
            <tbody>
              <tr>
                <td class="tdLabel"></td>
                <td><input type="text" name="reportDataDto.communicationEquipments[2].totalUnitsTested" size="10"
                    value="" id="reviewInspectionForm_reportDataDto_communicationEquipments_2__totalUnitsTested"
                    [(ngModel)]="qtd[findQuestionId('Radio')]['unitsTested']"
                    (change)="updateModel('Radio', 'unitsTested', $event.target.value)"
                    [disabled]="reviewInspection"></td>
              </tr>
            </tbody>
          </table>
        </td>
        <td align=" center">
          <table class="sample1">
            <tbody>
              <tr>
              </tr>
              <tr>
                <td class="tdLabel"></td>
                <td>

                  <mat-radio-group aria-label="Select an option"
                  [(ngModel)]="qtd[findQuestionId('Radio')]['resultsAcceptable']"
                  >
                    <mat-radio-button name="reportDataDto.communicationEquipments[2].resultsAccepted"
                    id="reviewInspectionForm_reportDataDto_communicationEquipments_2__resultsAcceptedN/A"
                    value="N/A"
                    (change)="updateModel('Radio', 'resultsAcceptable', $event.value)"
                    [disabled]="reviewInspection">N/A</mat-radio-button>
                    <mat-radio-button name="reportDataDto.communicationEquipments[2].resultsAccepted"
                    id="reviewInspectionForm_reportDataDto_communicationEquipments_2__resultsAcceptedYes" value="Yes"
                    (change)="updateModel('Radio', 'resultsAcceptable', $event.value)"
                    [disabled]="reviewInspection">Yes</mat-radio-button>
                    <mat-radio-button name="reportDataDto.communicationEquipments[2].resultsAccepted"
                    id="reviewInspectionForm_reportDataDto_communicationEquipments_2__resultsAcceptedNo" value="No"
                    (change)="updateModel('Radio', 'resultsAcceptable', $event.value)"
                    [disabled]="reviewInspection">No</mat-radio-button>
                  </mat-radio-group>

                  <!-- <input type="radio" name="reportDataDto.communicationEquipments[2].resultsAccepted"
                    id="reviewInspectionForm_reportDataDto_communicationEquipments_2__resultsAcceptedN/A"
                    checked="checked" value="N/A"
                    [(ngModel)]="communicationTable.radio.resultsAcceptable"
                    (change)="updateModel('Radio', 'resultsAcceptable', $event.target.value)"
                    [disabled]="reviewInspection"><label
                    for="reviewInspectionForm_reportDataDto_communicationEquipments_2__resultsAcceptedN/A">N/A</label>
                  <input type="radio" name="reportDataDto.communicationEquipments[2].resultsAccepted"
                    id="reviewInspectionForm_reportDataDto_communicationEquipments_2__resultsAcceptedYes" value="Yes"
                    [(ngModel)]="communicationTable.radio.resultsAcceptable"
                    (change)="updateModel('Radio', 'resultsAcceptable', $event.target.value)"
                    [disabled]="reviewInspection"><label
                    for="reviewInspectionForm_reportDataDto_communicationEquipments_2__resultsAcceptedYes">Yes</label>
                  <input type="radio" name="reportDataDto.communicationEquipments[2].resultsAccepted"
                    id="reviewInspectionForm_reportDataDto_communicationEquipments_2__resultsAcceptedNo" value="No"
                    [(ngModel)]="communicationTable.radio.resultsAcceptable"
                    (change)="updateModel('Radio', 'resultsAcceptable', $event.target.value)"
                    [disabled]="reviewInspection"><label
                    for="reviewInspectionForm_reportDataDto_communicationEquipments_2__resultsAcceptedNo">No</label> -->
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    </tbody>
  </table>
</div>
